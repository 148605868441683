import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IncidentDTO } from "../../../../models/incident";

@Component({
  selector: "app-incident-popup",
  templateUrl: "./incident-popup.component.html",
  styleUrls: ["./incident-popup.component.scss"]
})
export class IncidentPopupComponent implements OnInit {
  public incidents: any[];
  public deviceId: string;

  constructor(
    public dialogRef: MatDialogRef<IncidentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.incidents = this.data.incidents;
    this.deviceId = this.data.deviceId;
  }

  public incidentProperties: any[] = [
    {
      label: "creationDate",
      property: "creationDate",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "title",
      property: "title",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "type",
      property: "type",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "lastAuthorizedSite",
      property: "lastAuthorizedSite",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "createdBy",
      property: "createdBy",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "assignedTo",
      property: "assignedTo",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "status",
      property: "status",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "priority",
      property: "priority",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "currentSite",
      property: "currentSite",
      canBeFiltered: true,
      width: 5
    }
  ];

  public onClose(): void {
    this.dialogRef.close();
  }

  public onClickIncident(incident: IncidentDTO): void {
    this.dialogRef.close(incident);
  }
}
