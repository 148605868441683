import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild,
  SimpleChanges,
  HostListener
} from "@angular/core";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { Input, Output } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  ACTIVE_STATUS,
  ACTIVE_STATUS_DETAIL
} from "../../../stores/data_store/status-store";
import { IncidentDialogComponent } from "../../pages/incident/incident-dialog/incident-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotifierService } from "angular-notifier";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-button-list",
  templateUrl: "./button-list.component.html",
  styleUrls: ["./button-list.component.scss"]
})
export class ButtonListComponent implements OnInit, OnChanges {
  @Output() displayMore: EventEmitter<void> = new EventEmitter();
  @Output() manageList: EventEmitter<any> = new EventEmitter();

  @Input() data: any[];
  @Input() type: string;
  @Input() label: string;
  @Input() checkedData: any[] = [];
  @Input() showType: string = "show";
  @Input() iconOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isEmpty: boolean;
  @Input() icon: string;
  @Input() maxItems: number;
  @Input() pathTranslate: string;
  @Input() defaultRadioValue: string;

  private readonly notifier: NotifierService;
  private innerWidth: number;

  public displayedColumns: string[] = ["checkedItem", "key"];
  public searchValueUpdate = new Subject<string>();
  public dataSource = new MatTableDataSource();
  public allFiltersActive: boolean = false;
  public callApply: boolean = false;
  public searchValue: string = "";
  public activeData: number = 0;
  public searchData: any[] = [];
  public radioItem: string;

  private index: number = 20;
  private debounceTime: number = 400;
  private readonly STATE_BATCH: number = 12;

  @ViewChild("search", { static: false }) inputElement: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.searchValueUpdate
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((value) => {
        this.manageSearchBar(value);
      });
  }

  public ngOnInit(): void {
    if (this.type === "range") {
      this.callApply = true;
    }
    if (this.type === "radio" && this.defaultRadioValue) {
      this.radioItem = this.defaultRadioValue;
      this.activeData = 1;
    }
    this.innerWidth = window.innerWidth;
    this.onResize(undefined);
  }

  @HostListener("window:resize", ["$event"])
  public onResize(_event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1200) {
      this.iconOnly = true;
    } else if (this.innerWidth > 1200 && this.label !== "addIncident") {
      this.iconOnly = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxItems && this.maxItems) {
      if (this.checkedData.length > this.maxItems) {
        this.clearCheckbox();
      }
    }
    if (this.data) {
      if (this.type === "colorStatus") {
        let allActive: boolean = true;
        let filtersActive: number = 0;

        for (const filter of this.data) {
          if (!filter[this.showType]) allActive = false;
          else filtersActive++;
        }
        this.allFiltersActive = allActive;
        this.activeData =
          filtersActive === this.data.length ? 0 : filtersActive;
      } else if (this.type === "checkbox") {
        if (this.checkedData?.length) {
          this.activeData = this.checkedData.length;
        }
        this.updataDataSource();
      }
    }
  }

  public updataDataSource() {
    if (!this.searchValue) {
      this.dataSource.data = this.data.slice(0, this.index);
    } else {
      this.dataSource.data = this.searchData.slice(0, this.index);
    }
  }

  public sortItem(): void {
    setTimeout(() => {
      this.updataDataSource();
    }, this.debounceTime);
  }

  public manageItem(item, event?): void {
    if (event) event.preventDefault();

    if (this.maxItems <= this.checkedData.length && !item.checked) {
      let errorMsg: string = "limit items exceed";

      this.translate
        .get("filter.buttonListError", {
          maxItems: this.maxItems
        })
        .subscribe((message) => {
          errorMsg = message;
        });

      this.notifier.notify("default", errorMsg);
      return;
    }

    const index: number = this.data.findIndex((e) => e.key === item.key);

    if (index < 0) return;
    this.callApply = true;
    this.data[index].checked = !item.checked;
    if (item.checked) {
      this.checkedData.push(item);
    } else {
      this.checkedData.splice(
        this.checkedData.findIndex(({ key }) => key === item.key),
        1
      );
    }
  }

  public manageItemRadio(item): void {
    this.callApply = true;
    this.radioItem = item.value;
  }

  public manageRange(rangeType: string, value: string) {
    this.callApply = true;

    if (rangeType === "start") {
      this.data[0] = value;
    } else {
      this.data[1] = value;
    }
  }

  public manageDates(dateType: string, date: Date): void {
    this.callApply = true;

    if (dateType === "start") {
      this.data[0] = date;
    } else {
      this.data[1] = date;
    }
  }

  public manageSearchBar(value: string): void {
    this.searchData = this.data.filter((item) =>
      item.keyDisplayed.toLowerCase().includes(value.toLowerCase())
    );
    this.updataDataSource();
  }

  private applyFilter(): void {
    this.activeData = this.checkedData.length;
    this.callApply = false;
    this.manageList.emit(
      this.checkedData.map((item) => {
        const { key, keyDisplayed, ...newItem } = item;
        return newItem;
      })
    );
    this.sortItem();
    if (this.label === "addIncident" && !this.checkedData?.length) {
      this.trigger.closeMenu();
    }
  }

  private applyRadio(): void {
    this.activeData = 1;
    this.callApply = false;
    this.manageList.emit(this.radioItem);
  }

  private applyRange(): void {
    this.callApply = false;
    const start: number = parseInt(this.data[0], 10);
    const end: number = parseInt(this.data[1], 10);

    if (start >= 0 && end >= 0) {
      this.activeData = 1;
      this.manageList.emit([start, end]);
    }
  }

  private applyDates(): void {
    this.callApply = false;
    this.manageList.emit(this.data);
  }

  public applyItems(forceApply?: boolean): void {
    if (this.callApply || forceApply) {
      switch (this.type) {
        case "radio":
          this.applyRadio();
          return;
        case "range":
          this.applyRange();
          return;
        case "dateRange":
          this.applyDates();
          return;
        default:
          this.applyFilter();
          return;
      }
    }
  }

  public applyClosed(): void {
    if (this.label !== "addIncident") {
      this.applyItems();
    }
  }

  private clearCheckbox(): void {
    this.data = this.data.map((item) => {
      return { ...item, checked: false };
    });
    if (this.searchValue) {
      this.searchData = this.data.filter((item) =>
        item.keyDisplayed.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    }

    this.checkedData = [];
    this.updataDataSource();
    if (this.activeData && this.label !== "addIncident") {
      this.callApply = true;
      this.applyItems();
    }
  }

  private clearRadio(): void {
    this.activeData = 0;
    this.callApply = false;
    this.radioItem = undefined;
    this.manageList.emit(this.radioItem);
  }

  public clearRange(): void {
    this.activeData = 0;
    this.callApply = false;
    this.manageList.emit(undefined);
  }

  private clearDateRange(): void {
    this.callApply = false;
    this.manageList.emit(undefined);
  }

  private clearFilters(): void {
    this.store.dispatch({ type: ACTIVE_STATUS });
  }

  private clearFiltersDetail(): void {
    this.store.dispatch({ type: ACTIVE_STATUS_DETAIL });
  }

  public clearItems(): void {
    switch (this.type) {
      case "radio":
        this.clearRadio();
        return;
      case "checkbox":
        this.clearCheckbox();
        return;
      case "range":
        this.clearRange();
        return;
      case "dateRange":
        this.clearDateRange();
        return;
      case "colorStatus":
        this.showType === "show"
          ? this.clearFilters()
          : this.clearFiltersDetail();
        return;
      default:
        return;
    }
  }

  public loadMore = () => {
    let newIndex: number = this.index;
    if (newIndex !== this.data.length) {
      newIndex += this.STATE_BATCH;
      if (newIndex > this.data.length) {
        newIndex = this.data.length;
      }
      this.index = newIndex;
      this.updataDataSource();
    }
  };

  public searchBarFocus(): void {
    if (
      !this.isEmpty &&
      this.inputElement &&
      this.type !== "dateRange" &&
      this.type !== "range"
    ) {
      this.inputElement.nativeElement.focus();
    }
  }

  public manageFilter(item) {
    this.manageList.emit(item);
  }

  openDialog() {
    const dialogRef = this.dialog.open(IncidentDialogComponent, {
      maxWidth: "90vw",
      minHeight: "85vh",
      width: "90vw",
      height: "85vh",
      data: {
        incidents: this.data,
        checkedData: this.checkedData,
        label: this.label
      }
    });

    if (!this.isEmpty) {
      dialogRef.componentInstance.manageIncident.subscribe((incident) => {
        this.manageItem(incident);
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "incidentApply") {
        if (this.label === "addIncident" && !this.checkedData?.length) {
          this.applyItems(true);
        } else {
          this.applyItems();
        }
        this.trigger.closeMenu();
      }
    });
  }

  public ondisplayMore(): void {
    this.displayMore.emit();
    this.openDialog();
  }

  public interpolatePathTranslate(item: string): string {
    return this.pathTranslate
      ? this.pathTranslate.replace("{{item}}", item)
      : item;
  }
}
