<div class="headerContainer">
  <div class="headerFilter">
    <app-filter-button
      [type]="'checkbox'"
      [label]="'containerType'"
      [icon]="'container'"
      [checkedData]="filterContainers"
      (manageList)="manageContainers($event)"
    ></app-filter-button>
  </div>
  <div class="battery-holder" *ngIf="batteryVisible">
    <app-toggle-checkbox (toggle)="tglBattery()"></app-toggle-checkbox>
    <img class="battery" src="/assets/img/battery/green.svg" />
  </div>
</div>
