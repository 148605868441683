<div
  [matMenuTriggerFor]="menuList"
  [ngClass]="{
    'filter-site': true,
    'filter-site-active': inputActive
  }"
>
  <span (click)="inputFocus()">
    <svg-icon class="filter-site-icon" [name]="iconMap[type]"></svg-icon>
  </span>

  <input
    #inputRef
    class="filter-site-input"
    type="text"
    title="Search"
    [(ngModel)]="inputData"
    (ngModelChange)="loading = true; searchValueUpdate.next($event)"
    [placeholder]="'misc.placeholder.' + type | translate"
    (keyup.enter)="inputEnter()"
  />

  <div
    class="clear-site-button-wrapper"
    *ngIf="inputData"
    (click)="clearInput($event)"
  >
    <svg-icon class="clear-site-button" [name]="'close'"></svg-icon>
  </div>
</div>

<mat-menu
  class="filter-site-matmenu"
  #menuList="matMenu"
  (closed)="menuClosed()"
>
  <div *ngIf="!inputData" class="site-menu-empty">
    {{ "searchbarSite.empty" | translate }}
  </div>
  <div *ngIf="inputData && sitesMatch?.length" class="site-menu-match-panel">
    <div class="site-matmenu-title">
      <p>{{ "searchbarSite.match" | translate }}</p>
    </div>
    <div (click)="$event.stopPropagation()">
      <div class="filter-site-list">
        <mat-table #table [dataSource]="matchDataSource">
          <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[1] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.key }}
            </mat-cell>
          </ng-container>

          <mat-row
            *matRowDef="let row; columns: displayedColumns; element"
            (click)="manageItem($event, row)"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
  <div *ngIf="inputData">
    <div class="site-matmenu-title">
      <p>{{ "searchbarSite.results" | translate }}</p>
    </div>
    <div *ngIf="!loading && !data?.length" class="site-matmenu-results-empty">
      {{ "searchbarSite.noResults" | translate }}
    </div>
    <div *ngIf="data?.length" (click)="$event.stopPropagation()">
      <div
        class="filter-site-list"
        [infiniteScroll]="loadMore"
        [scrollRatio]="0.66"
        [initScrollThreshold]="200"
      >
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[1] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.key }}
            </mat-cell>
          </ng-container>

          <mat-row
            *matRowDef="let row; columns: displayedColumns; element"
            (click)="manageItem($event, row)"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</mat-menu>
