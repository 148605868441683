import { Store } from "@ngrx/store";
import { Observable, of, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import {
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Input, Output } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatTableDataSource } from "@angular/material/table";

import {
  FiltersControllerService,
  FiltersOptions,
  FiltersData,
  FilterInputData
} from "../../../services/filters.service";
import {
  ACTIVE_STATUS,
  ACTIVE_STATUS_DETAIL
} from "../../../stores/data_store/status-store";
import { FilterStatus } from "../../../models/filters";
import { IncidentDialogComponent } from "../../pages/incident/incident-dialog/incident-dialog.component";
import { Range } from "../../../models/range-status-days";

@Component({
  selector: "app-filter-button",
  templateUrl: "./filter-button.component.html",
  styleUrls: ["./filter-button.component.scss"]
})
export class FilterButtonComponent implements OnInit, OnChanges {
  @Output() manageList: EventEmitter<any> = new EventEmitter();
  @Output() displayMore: EventEmitter<void> = new EventEmitter();

  @Input() type: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() maxItems: number;
  @Input() pathTranslate: string;
  @Input() isEmpty: boolean = false;
  @Input() iconOnly: boolean = false;
  @Input() showType: string = "show";
  @Input() defaultRadioValue: string;
  @Input() isLoading: boolean = false;
  @Input() checkedData: Array<string | number> = [];
  @Input() rangeData?: Range | undefined;

  @Input() radioData: string[];
  @Input() dateRangeData: Date[];
  @Input() inputData: FilterInputData;
  @Input() colorStatusData: FilterStatus[];

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild("search", { static: false }) inputElement: ElementRef;

  public rangeValues: Range = {};
  private skip: number = 0;
  private limit: number = 100;
  private debounceTime: number = 400;
  private subscription: Subscription = new Subscription();
  private filterMethodsMap!: {
    [key: string]: (filters: FiltersOptions) => Observable<FiltersData[]>;
  };

  private readonly notifier: NotifierService;

  public data: FiltersData[] = [];
  public dataLoading: boolean = false;
  public dataFetched: boolean = false;
  public allDataFetched: boolean = false;

  public radioItem: string;
  public activeData: number = 0;
  public callApply: boolean = false;
  public allFiltersActive: boolean = false;
  public dataSource = new MatTableDataSource([]);
  public matchDataSource = new MatTableDataSource([]);
  public displayedColumns: string[] = ["checkedItem", "key"];

  public searchValue: string = "";
  public searchValueUpdate = new Subject<string>();

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private translate: TranslateService,
    private notifierService: NotifierService,
    private filtersControllerService: FiltersControllerService
  ) {
    this.notifier = notifierService;

    this.searchValueUpdate
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(() => {
        this.resetData();
        this.updataDataSource();
      });
  }

  public ngOnInit(): void {
    this.activeData = this.checkedData.length;
    // Initialize fetch data callback functions
    this.filterMethodsMap = {
      containerType: this.filtersControllerService.getFiltersContainers.bind(
        this.filtersControllerService
      ),
      device: this.filtersControllerService.getFiltersDevices.bind(
        this.filtersControllerService
      ),
      lastAuthorizedSite: this.filtersControllerService.getFiltersSites.bind(
        this.filtersControllerService
      ),
      sites: this.filtersControllerService.getFiltersSites.bind(
        this.filtersControllerService
      ),
      departureSites: this.filtersControllerService.getFiltersSites.bind(
        this.filtersControllerService
      ),
      arrivalSites: this.filtersControllerService.getFiltersSites.bind(
        this.filtersControllerService
      ),
      days: this.filtersControllerService.getFilterStatusDays.bind(
        this.filtersControllerService
      ),
      radius: () =>
        of(
          this.filtersControllerService.getFilterRadius({
            skip: this.skip,
            limit: this.limit,
            search: this.searchValue
          })
        ),
      incident: this.filtersControllerService.getFilterIncidentsOpened.bind(
        this.filtersControllerService
      ),
      addIncident: this.filtersControllerService.getFilterIncidents.bind(
        this.filtersControllerService
      )
    };

    if (this.type === "radio" && this.defaultRadioValue) {
      if (typeof this.checkedData[0] === "string") {
        this.radioItem = this.defaultRadioValue;
      }
      this.activeData = 1;
    }

    if (this.type === "range") {
      this.rangeValues = this.rangeData;
      if (
        this.rangeValues.start !== undefined &&
        this.rangeValues.end !== undefined
      ) {
        this.activeData = 1;
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxItems && this.maxItems) {
      if (this.checkedData.length > this.maxItems) {
        this.clearCheckbox();
      }
    }
    if (this.colorStatusData && this.type === "colorStatus") {
      let allActive: boolean = true;
      let filtersActive: number = 0;

      for (const filter of this.data) {
        if (!filter[this.showType]) allActive = false;
        else filtersActive++;
      }
      this.allFiltersActive = allActive;
      this.activeData = filtersActive === this.data.length ? 0 : filtersActive;
    }
  }

  public searchBarFocus(): void {
    if (
      !this.isEmpty &&
      this.inputElement &&
      this.type !== "dateRange" &&
      this.type !== "range"
    ) {
      this.inputElement.nativeElement.focus();
    }
  }

  public updateMatchDataSource(): void {
    this.matchDataSource.data = this.data.filter(({ key }) => {
      if (typeof key === "string") {
        key = key.toUpperCase();
      } else if (typeof key === "number") {
        key = key.toString();
      }

      // deviceIds filter handling
      if (key.startsWith("SNT_") || key.startsWith("SFX_")) {
        key = key.slice(4);

        return this.searchValue.toUpperCase().includes(key);
      }

      return key === this.searchValue.toUpperCase();
    });
  }

  public updataDataSource(): void {
    this.dataLoading = true;
    this.subscription.add(
      this.filterMethodsMap[this.label]({
        skip: this.skip,
        limit: this.limit,
        search: this.searchValue
      }).subscribe((filtersData: FiltersData[]) => {
        filtersData = filtersData.map((filterData) => {
          return {
            ...filterData,
            checked: this.checkedData.includes(filterData.key)
          };
        });
        this.data = [...this.data, ...filtersData];
        this.dataSource.data = this.data;
        if (this.searchValue) {
          this.updateMatchDataSource();
        }
        this.skip += this.limit;
        this.dataLoading = false;
        this.dataFetched = true;

        if (filtersData?.length < this.limit) {
          this.allDataFetched = true;
        }
      })
    );
  }

  public manageItem(item, event?): void {
    if (event) event.preventDefault();

    if (this.maxItems <= this.checkedData.length && !item.checked) {
      let errorMsg: string = "limit items exceed";

      this.translate
        .get("filter.buttonListError", {
          maxItems: this.maxItems
        })
        .subscribe((message) => {
          errorMsg = message;
        });

      this.notifier.notify("default", errorMsg);
      return;
    }

    const index: number = this.data.findIndex((e) => e.key === item.key);
    if (index < 0) return;
    this.callApply = true;
    if (!item.checked) {
      this.checkedData.push(item.key);
    } else {
      this.checkedData.splice(
        this.checkedData.findIndex((key) => key === item.key),
        1
      );
    }
    this.data[index].checked = !item.checked;
  }

  public manageItemRadio(item): void {
    this.callApply = true;
    this.radioItem = item.value;
  }

  public manageDates(dateType: string, date: Date): void {
    this.callApply = true;

    if (dateType === "start") {
      this.dateRangeData[0] = date;
    } else {
      this.dateRangeData[1] = date;
    }
  }

  public manageRange(type: "start" | "end", event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.valueAsNumber;

    if (isNaN(value)) {
      value = undefined;
    } else {
      value = Math.min(Math.max(value, 0), 361);
    }

    if (type === "start") {
      this.rangeValues.start = value;
    } else if (type === "end") {
      this.rangeValues.end = value;
    }

    // Permet de gérer le cas ou l'input = ""
    if (value !== undefined) {
      if (inputElement.value !== value.toString()) {
        inputElement.value = value.toString();
      }
    }

    if (
      this.rangeValues.start === undefined &&
      this.rangeValues.end === undefined
    ) {
      this.rangeValues = {};
      this.activeData = 0;
    } else {
      this.activeData = 1;
    }
  }

  public manageFilter(item) {
    this.manageList.emit(item);
  }

  public loadMore = () => {
    if (!this.dataLoading && !this.allDataFetched) this.updataDataSource();
  };
  public resetData(): void {
    this.skip = 0;
    this.data = [];
    this.matchDataSource.data = [];
    this.dataFetched = false;
    this.allDataFetched = false;
  }

  public menuOpened(): void {
    if (!this?.data.length && this.type === "checkbox") {
      this.updataDataSource();
    }

    this.searchBarFocus();
  }

  private applyRadio(): void {
    this.activeData = 1;
    this.callApply = false;
    this.manageList.emit(this.radioItem);
  }

  private applyDates(): void {
    this.callApply = false;
    this.manageList.emit(this.dateRangeData);
  }

  private applyRange(): void {
    this.callApply = false;

    // Remplissage automatique quand start est non remplit
    if (
      this.rangeValues?.start === undefined &&
      this.rangeValues?.end !== undefined
    ) {
      this.rangeValues.start = 0;
    }

    // Remplissage automatique quand end est non remplit

    if (
      this.rangeValues?.start !== undefined &&
      this.rangeValues?.end === undefined
    ) {
      this.rangeValues.end = 361;
    }
    // Inversion quand start est plus grand que end
    if (
      this.rangeValues.start !== undefined &&
      this.rangeValues.end !== undefined &&
      this.rangeValues.start > this.rangeValues.end
    ) {
      this.rangeValues = {
        start: this.rangeValues.end,
        end: this.rangeValues.start
      };
    }

    this.manageList.emit(this.rangeValues);

    if (
      (this.type === "range" || this.type === "dateRange") &&
      this.inputData
    ) {
      this.activeData =
        this.rangeValues.start !== undefined &&
        this.rangeValues.end !== undefined
          ? 1
          : 0;
    }
  }

  private applyFilter(): void {
    this.activeData = this.checkedData.length;
    this.callApply = false;
    this.manageList.emit(this.checkedData);
    if (this.label === "addIncident" && !this.checkedData?.length) {
      this.trigger.closeMenu();
    }
  }

  public applyItems(forceApply?: boolean): void {
    if (this.type === "range") {
      forceApply = true;
    }
    if (this.callApply || forceApply) {
      switch (this.type) {
        case "radio":
          this.applyRadio();
          return;
        case "dateRange":
          this.applyDates();
          return;
        case "range":
          this.applyRange();
          return;
        default:
          this.applyFilter();
          return;
      }
    }
  }

  private clearFilters(): void {
    this.store.dispatch({ type: ACTIVE_STATUS });
  }

  private clearFiltersDetail(): void {
    this.store.dispatch({ type: ACTIVE_STATUS_DETAIL });
  }

  private clearDateRange(): void {
    this.callApply = false;
    this.manageList.emit(undefined);
  }

  private clearRadio(): void {
    this.activeData = 0;
    this.callApply = false;
    this.radioItem = undefined;
    this.manageList.emit(this.radioItem);
  }

  private clearCheckbox(): void {
    this.data = this.data.map((item) => {
      return { ...item, checked: false };
    });
    this.updateMatchDataSource();

    this.checkedData = [];
    this.dataSource.data = this.data;
    if (this.activeData && this.label !== "addIncident") {
      this.callApply = true;
      this.applyItems();
    }
  }

  clearRange(): void {
    this.rangeValues = {};
    this.callApply = false;
    this.activeData = 0;
    this.manageList.emit(this.rangeValues);
  }

  public clearItems(): void {
    switch (this.type) {
      case "radio":
        this.clearRadio();
        return;
      case "checkbox":
        this.clearCheckbox();
        return;
      case "dateRange":
        this.clearDateRange();
        return;
      case "colorStatus":
        this.showType === "show"
          ? this.clearFilters()
          : this.clearFiltersDetail();
        return;
      case "range":
        this.clearRange();
        return;
      case "filter":
      default:
        return;
    }
  }

  public applyClosed(): void {
    if (this.label !== "addIncident") {
      this.applyItems();
    }
  }

  public openDialog() {
    const dialogRef = this.dialog.open(IncidentDialogComponent, {
      maxWidth: "90vw",
      minHeight: "85vh",
      width: "90vw",
      height: "85vh",
      data: {
        incidents: this.data,
        checkedData: this.checkedData,
        label: this.label
      }
    });

    if (!this.isEmpty) {
      dialogRef.componentInstance.manageIncident.subscribe((incident) => {
        this.manageItem(incident);
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "incidentApply") {
        if (this.label === "addIncident" && !this.checkedData?.length) {
          this.applyItems(true);
        } else {
          this.applyItems();
        }
        this.trigger.closeMenu();
      }
    });
  }

  public ondisplayMore(): void {
    this.displayMore.emit();
    this.openDialog();
  }

  public interpolatePathTranslate(item: string): string {
    return this.pathTranslate
      ? this.pathTranslate.replace("{{item}}", item)
      : item;
  }
}
