import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges
} from "@angular/core";

@Component({
  selector: "app-header-analytics",
  templateUrl: "./header-analytics.component.html",
  styleUrls: ["./header-analytics.component.scss"]
})
export class HeaderAnalyticsComponent implements OnChanges {
  @Output() departureSites: EventEmitter<string[]> = new EventEmitter();
  @Output() arrivalSites: EventEmitter<string[]> = new EventEmitter();
  @Output() containers: EventEmitter<string[]> = new EventEmitter();
  @Output() sites: EventEmitter<string[]> = new EventEmitter();
  @Output() graphic: EventEmitter<string> = new EventEmitter();
  @Output() dates: EventEmitter<Date[]> = new EventEmitter();
  @Output() days: EventEmitter<number> = new EventEmitter();

  @Input() filterContainers: string[] = [];
  @Input() filterSites: string[] = [];
  @Input() filterDepartureSites: string[] = [];
  @Input() filterArrivalSites: string[] = [];
  @Input() filterDateStart: Date;
  @Input() filterDateEnd: Date;
  @Input() filterDays: number;

  @Input() graphNames: string[];
  @Input() graphConfig: any;

  public filterDates: Date[];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterDateStart || changes.filterDateEnd) {
      this.filterDates = [this.filterDateStart, this.filterDateEnd];
    }
  }

  public manageGraphics(graphic: string): void {
    this.graphic.emit(graphic);
  }

  public manageContainers(containers): void {
    this.containers.emit(containers);
  }

  public manageSites(sites: string[]): void {
    this.sites.emit(sites);
  }

  public manageDepartureSites(sites: string[]): void {
    this.departureSites.emit(sites);
  }

  public manageArrivalSites(sites: string[]): void {
    this.arrivalSites.emit(sites);
  }

  public manageDates(dates) {
    this.dates.emit(dates);
  }

  public manageDays(days) {
    this.days.emit(days);
  }
}
