import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output
} from "@angular/core";
import { NotifierService } from "angular-notifier";

import { IKpiAlertSites } from "psa-tnt-models";
import { ContainerTypeDTO } from "../../../models/container-type";
import { IKpiBatteryLevelsDTO, IKpiStateColorsDTO } from "psa-tnt-models";

import {
  DeviceContainerStateControllerService,
  defaultTornOption
} from "../../../services/device-container-state-controller.service";

@Component({
  selector: "app-kpi",
  templateUrl: "./kpi.component.html",
  styleUrls: ["./kpi.component.scss"]
})
export class KpiComponent implements OnInit, OnDestroy {
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  private subscription: Subscription = new Subscription();

  private readonly notifier: NotifierService;

  public allContainersTypes: ContainerTypeDTO[];
  public filterContainers: string[] = [];
  public filterTorn: string = defaultTornOption;

  public alertSites: IKpiAlertSites[] = [];
  public statusIndicators: IKpiStateColorsDTO;
  public batteryIndicators: IKpiBatteryLevelsDTO;
  public loading = {
    stateColors: true,
    battery: true,
    alertSites: true
  };

  constructor(
    private store: Store<any>,
    private deviceContainerStateControllerService: DeviceContainerStateControllerService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.initFilters();
    this.initStore();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initFilters(): void {
    if (JSON.parse(localStorage.getItem("filterContainers")) !== null) {
      this.filterContainers = JSON.parse(
        localStorage.getItem("filterContainers")
      );
    }
    if (localStorage.getItem("filterTorn")) {
      this.filterTorn = localStorage.getItem("filterTorn");
    }
  }

  private initStore(): void {
    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((containers: ContainerTypeDTO[]) => {
          this.allContainersTypes = containers;
          this.refreshData();
        })
    );
  }

  private refreshData() {
    if (
      this.allContainersTypes.length !== 0 &&
      this.deviceContainerStateControllerService.getStateColorsIndicator({
        requestId: 0,
        filterContainers: this.filterContainers,
        containerTypes: this.allContainersTypes
      }) !== null
    ) {
      this.subscription.add(
        this.deviceContainerStateControllerService
          .getStateColorsIndicator({
            requestId: 0,
            filterContainers: this.filterContainers,
            filterTorn: this.filterTorn,
            containerTypes: this.allContainersTypes
          })
          .subscribe(
            (kpi: IKpiStateColorsDTO) => {
              this.statusIndicators = kpi;
            },
            () => {
              this.notifier.notify(
                "error",
                "State Colors indicators request failed."
              );
            },
            () => {
              this.loading.stateColors = false;
            }
          )
      );

      this.subscription.add(
        this.deviceContainerStateControllerService
          .getBatteryColors({
            requestId: 0,
            filterContainers: this.filterContainers,
            filterTorn: this.filterTorn,
            containerTypes: this.allContainersTypes
          })
          .subscribe(
            (kpi: IKpiBatteryLevelsDTO) => {
              this.batteryIndicators = kpi;
            },
            () => {
              this.notifier.notify(
                "error",
                "Battery indicators request failed."
              );
            },
            () => {
              this.loading.battery = false;
            }
          )
      );

      this.subscription.add(
        this.deviceContainerStateControllerService
          .getAlertSites({
            requestId: 0,
            filterContainers: this.filterContainers,
            containerTypes: this.allContainersTypes
          })
          .subscribe(
            (alertSites: IKpiAlertSites[]) => {
              this.alertSites = alertSites;
            },
            () => {
              this.notifier.notify(
                "error",
                "Alerts By Sites indicators request failed."
              );
            },
            () => {
              this.loading.alertSites = false;
            }
          )
      );
    }
  }

  public changeContainers(containers: string[]) {
    containers?.length
      ? localStorage.setItem("filterContainers", JSON.stringify(containers))
      : localStorage.removeItem("filterContainers");
    this.filterContainers = containers;
    this.refreshData();
  }

  public changeTorn(torn: string) {
    localStorage.setItem("filterTorn", torn);
    this.filterTorn = torn;
    this.refreshData();
  }

  public getObjKeys(obj: object): string[] {
    return Object.keys(obj).filter((key) => key !== "total");
  }
}
