<div class="header-kpi-container">
  <div class="header-kpi-filter">
    <app-filter-button
      [type]="'checkbox'"
      [label]="'containerType'"
      [icon]="'container'"
      [checkedData]="filterContainers"
      (manageList)="manageContainers($event)"
    ></app-filter-button>
    <app-filter-button
      [type]="'radio'"
      [label]="'torn'"
      [icon]="'torn'"
      [radioData]="tornFilterOptions"
      [defaultRadioValue]="filterTorn"
      (manageList)="manageTorn($event)"
      [isEmpty]="!tornFilterOptions.length"
      [checkedData]="filterTorn"
      [pathTranslate]="'filter.torn.label.{{item}}'"
    ></app-filter-button>
  </div>
</div>
