<h2 mat-dialog-title>
  {{ "incident.dialog.title" | translate }}
</h2>
<div *ngIf="allIncidents?.length">
  <mat-dialog-content>
    <app-manager-list
      class="list-element"
      (selectElement)="selectIncident($event)"
      (changeFilter)="changeFilterIncident($event)"
      (changePage)="changePageIncident($event)"
      [displayObjects]="incidents"
      [maxItems]="numberOfAllIncident"
      [properties]="incidentProperties"
      [hasPaginatorPanel]="false"
    ></app-manager-list>
  </mat-dialog-content>
  <mat-dialog-actions class="incident-dialog-buttons">
    <app-button
      (clicked)="onClose()"
      class="incident-dialog-button"
      [labelPath]="'misc.cancel'"
    ></app-button>
    <app-button
      (clicked)="onApply()"
      class="incident-dialog-button"
      [type]="'filled'"
      [labelPath]="!isNewIncident ? 'misc.apply' : 'misc.new'"
    ></app-button>
  </mat-dialog-actions>
</div>

<div *ngIf="!allIncidents?.length" class="incident-dialog-empty">
  <p *ngIf="incidentsFetched">{{ "misc.empty" | translate }}</p>
  <mat-spinner *ngIf="!incidentsFetched" [diameter]="16"></mat-spinner>
  <mat-dialog-actions class="incident-dialog-buttons">
    <app-button
      (clicked)="onClose()"
      class="incident-dialog-button"
      [labelPath]="'misc.cancel'"
    ></app-button>
  </mat-dialog-actions>
</div>
