import { Component, Inject, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Subscription } from "rxjs";

import { IncidentDTO } from "../../../../models/incident";
import { IncidentControllerService } from "../../../../services/incident-controller.service";

@Component({
  selector: "app-incident-dialog",
  styleUrls: ["incident-dialog.component.scss"],
  templateUrl: "incident-dialog.component.html"
})
export class IncidentDialogComponent implements OnInit {
  @Output() manageIncident: EventEmitter<any[]> = new EventEmitter();

  private subscription: Subscription = new Subscription();

  public allIncidents: IncidentDTO[];
  public incidents: IncidentDTO[];
  public incidentsFetched: boolean = false;

  public numberOfAllIncident: number = 0;
  public isNewIncident: boolean = false;
  public filterIncident: any;
  public incidentProperties: any[] = [
    {
      property: "checked",
      canBeFiltered: false,
      width: 5
    },
    {
      label: "creationDate",
      property: "creationDate",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "title",
      property: "title",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "type",
      property: "type",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "lastAuthorizedSite",
      property: "lastAuthorizedSite",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "createdBy",
      property: "createdBy",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "assignedTo",
      property: "assignedTo",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "status",
      property: "status",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "priority",
      property: "priority",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "currentSite",
      property: "currentSite",
      canBeFiltered: true,
      width: 5
    }
  ];

  constructor(
    public incidentServices: IncidentControllerService,
    public dialogRef: MatDialogRef<IncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    const incidentIds: number[] = this.data.incidents.map(({ key }) => key);
    this.subscription.add(
      this.incidentServices.getAllIncidentsByIncidentID(incidentIds).subscribe(
        (incidents) => {
          this.allIncidents = incidents.map((incident) => {
            return {
              ...incident,
              key: incident.id,
              keyDisplayed: incident.title,
              checked: this.data.checkedData.includes(incident.id)
            };
          });
          this.incidents = this.allIncidents;
          this.incidentsFetched = true;
        },
        (error) => {
          console.log(error);
          this.incidents = [];
          this.incidentsFetched = true;
        }
      )
    );

    this.setIsNewIncident();
  }

  private setIsNewIncident(): void {
    this.isNewIncident =
      this.data.label === "addIncident" && !this.data.checkedData?.length;
  }

  public selectIncident(incident): void {
    this.manageIncident.emit(incident);

    incident.checked = !incident.checked;
    this.setIsNewIncident();
  }

  private getRegex(reg: string): RegExp {
    const parseReg = reg.split("/");

    return new RegExp(parseReg[0], parseReg[1]);
  }

  public updateIncidents(): void {
    const filterType: string = this.filterIncident?.or ? "or" : "and";
    const props: string[] = Object.keys(
      filterType === "or" ? this.filterIncident.or : this.filterIncident.and
    );

    this.incidents = this.allIncidents.filter((incident) => {
      let isValid: boolean = filterType === "and";

      for (let prop of props) {
        isValid = this.getRegex(
          this.filterIncident[filterType][prop].regexp
        ).test(incident[prop]);
        if (
          (filterType === "or" && isValid) ||
          (filterType === "and" && !isValid)
        )
          break;
      }
      return isValid;
    });
  }

  public changeFilterIncident(filter): void {
    if (!filter) {
      this.filterIncident = undefined;
      this.incidents = this.allIncidents;
    } else {
      if (filter.search) {
        this.filterIncident = { or: {} };
        this.incidentProperties.forEach(({ property, canBeFiltered }) => {
          if (canBeFiltered) {
            this.filterIncident.or[property] = filter.search;
          }
        });
      } else {
        this.filterIncident = { and: filter };
      }
      this.updateIncidents();
    }
  }

  public changePageIncident(incident): void {
    console.log("changePageIncident", incident);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onApply(): void {
    this.dialogRef.close("incidentApply");
  }
}
