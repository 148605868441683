import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CurrentStateDTO } from "../models/current-state";
import { CurrentStateFilterDTO } from "../models/current-state-filter";
import { CountDTO, HistoricalStateDTO } from "../models/historical-state";
import { KpisDTO } from "../models/kpis";
import { HttpClientWrapper } from "./http-client.wrapper";
import { IKpiAlertSites, IKpiBatteryLevelsDTO } from "psa-tnt-models";

/**
 * DeviceContainerStateController service call api rest for HistoricalState - return HistoricalStateDTO
 */

interface ResponseWrapper {
  requestId: number;
  partialCurrentStates: CurrentStateDTO[];
}

interface ResponseWrapperPacked {
  requestId: number;
  partialCurrentStates: string;
}

export const defaultTornOption: string = "noTorn";
export const filterTornOptions = {
  noTorn: false,
  all: undefined,
  torn: true
};
const sourcesHistorical: string[] = [
  "SHORT",
  "LONG",
  "BATCH",
  "BATCH_LATE",
  "BATCH_QUIET",
  "BATCH_STATIC",
  "TORN",
  "INIT",
  // sensolus sources:
  "START",
  "STOP",
  "PERIODIC",
  "ON_THE_MOVE"
];

@Injectable()
export class DeviceContainerStateControllerService {
  constructor(
    public httpClientHistorical: HttpClientWrapper<HistoricalStateDTO>,
    public httpClientCurrent: HttpClientWrapper<CurrentStateDTO>,
    public httpClientColor: HttpClientWrapper<KpisDTO>,
    public httpClientBattery: HttpClientWrapper<IKpiBatteryLevelsDTO>,
    public httpClientAlertSites: HttpClientWrapper<IKpiAlertSites[]>,
    public httpClientCount: HttpClientWrapper<CountDTO>,
    public httpClientAllCurrent: HttpClientWrapper<CurrentStateDTO>,
    public httpClientWrapper: HttpClientWrapper<ResponseWrapper>,
    public httpClientWrapperPacked: HttpClientWrapper<ResponseWrapperPacked>,
    public httpClientSlowLog: HttpClientWrapper<string>,
    public httpClientClusterDevice: HttpClientWrapper<CurrentStateDTO>
  ) {}

  getHistoricalStateByDeviceContainer(
    currentStateFilterDTO: CurrentStateFilterDTO
  ): Observable<HistoricalStateDTO[]> {
    let filter = "?filter=";
    let colorFilter: Object;
    let optionalFilter: Object = {};
    let listColor: string[] = [];
    let includeOrange: number = 0;
    if (currentStateFilterDTO.filtersColor) {
      for (const filterColor of currentStateFilterDTO.filtersColor) {
        if (filterColor.showDetail) {
          if (
            filterColor.marker.includes("orange") &&
            !listColor.includes("ORANGE")
          ) {
            includeOrange++;
          } else if (!filterColor.marker.includes("orange")) {
            listColor.push(filterColor.marker.toUpperCase());
          }
        }
      }
    } else {
      listColor = ["GREEN", "GREY", "RED", "BLUE", "ORANGE"];
    }
    if (
      currentStateFilterDTO.statusDays > 8640 ||
      currentStateFilterDTO.statusDays == undefined ||
      currentStateFilterDTO.statusDays <= 0
    ) {
      currentStateFilterDTO.statusDays = 8640; //limite à 1 an
    }

    if (
      currentStateFilterDTO.statusDays &&
      currentStateFilterDTO.statusDays !== -1
    ) {
      currentStateFilterDTO.statusDays =
        currentStateFilterDTO.statusDays * 3600;
      optionalFilter = {
        gt: Math.round(Date.now() / 1000 - currentStateFilterDTO.statusDays)
      };
    }

    const orFilter = [
      {
        statusColor: { inq: listColor },
        receivedMessageTime: optionalFilter,
        source: {
          inq: sourcesHistorical
        },
        deviceId: currentStateFilterDTO.filterDeviceId
      }
    ];

    if (currentStateFilterDTO.filtersColor && includeOrange) {
      if (includeOrange === 3) {
        orFilter[0].statusColor.inq.push("ORANGE");
      } else {
        for (const filterColor of currentStateFilterDTO.filtersColor) {
          if (filterColor.showDetail && filterColor.marker.includes("orange")) {
            const flagFilter: any = {
              statusColor: "ORANGE",
              [filterColor.flag]: true,
              deviceId: currentStateFilterDTO.filterDeviceId,
              source: {
                inq: sourcesHistorical
              }
            };

            orFilter.push(flagFilter);
          }
        }
      }
    }

    colorFilter = {
      where: {
        or: orFilter
      },
      skip: currentStateFilterDTO.skip,
      limit: currentStateFilterDTO.limit,
      order: "receivedMessageTime DESC"
    };

    filter = filter + JSON.stringify(colorFilter);

    return this.httpClientHistorical.get(
      environment.apiBaseUrl + "device-containers/historical" + filter
    );
  }

  getHistoricalCount(currentStateFilterDTO: CurrentStateFilterDTO) {
    let filter = "?where=";
    let listColor: string[] = [];
    let includeOrange: number = 0;

    if (currentStateFilterDTO.filtersColor) {
      for (const filterColor of currentStateFilterDTO.filtersColor) {
        if (filterColor.showDetail) {
          if (
            filterColor.marker.includes("orange") &&
            !listColor.includes("ORANGE")
          ) {
            includeOrange++;
          } else if (!filterColor.marker.includes("orange")) {
            listColor.push(filterColor.marker.toUpperCase());
          }
        }
      }
    } else {
      listColor = ["GREEN", "GREY", "RED", "BLUE", "ORANGE"];
    }
    //limit to 1 year
    const statusDays = 4320 * 3600 * 2;

    const optionalFilter = {
      gt: Math.round(Date.now() / 1000 - statusDays)
    };

    const orFilter = [
      {
        statusColor: { inq: listColor },
        deviceId: currentStateFilterDTO.filterDeviceId,
        receivedMessageTime: optionalFilter,
        source: {
          inq: sourcesHistorical
        }
      }
    ];

    if (currentStateFilterDTO.filtersColor && includeOrange) {
      if (includeOrange === 3) {
        orFilter[0].statusColor.inq.push("ORANGE");
      } else {
        for (const filterColor of currentStateFilterDTO.filtersColor) {
          if (filterColor.showDetail && filterColor.marker.includes("orange")) {
            const flagFilter: any = {
              statusColor: "ORANGE",
              [filterColor.flag]: true,
              deviceId: currentStateFilterDTO.filterDeviceId,
              source: {
                inq: sourcesHistorical
              }
            };

            orFilter.push(flagFilter);
          }
        }
      }
    }

    filter = filter + JSON.stringify({ or: orFilter });

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "device-containers/historical/count" + filter
    );
  }

  getStateColorsIndicator(currentStateFilterDTO: CurrentStateFilterDTO) {
    let cType = Array<string>();
    if (currentStateFilterDTO.filterContainers?.length) {
      cType = currentStateFilterDTO.filterContainers;
    } else {
      currentStateFilterDTO.containerTypes.forEach((containerType) => {
        if (containerType.checked) {
          cType.push(containerType.code);
        }
      });
    }
    const body: any = {
      containerTypes: cType,
      filter: {
        lastAuthorizedSites: currentStateFilterDTO.filterLastSites?.length
          ? currentStateFilterDTO.filterLastSites
          : undefined,
        statusDays:
          currentStateFilterDTO.filterStatusDays?.start !== undefined &&
          currentStateFilterDTO.filterStatusDays?.end !== undefined
            ? currentStateFilterDTO.filterStatusDays
            : undefined,
        confidenceRadiusInMeter: currentStateFilterDTO.filterConfidenceRadius
          ?.length
          ? currentStateFilterDTO.filterConfidenceRadius
          : undefined,
        torn: filterTornOptions[currentStateFilterDTO.filterTorn]
      }
    };

    return cType.length == 0
      ? null
      : this.httpClientColor.postUnique(
          environment.apiBaseUrl + "/device-containers/state-color-indicator",
          body
        );
  }

  getBatteryColors(currentStateFilterDTO: CurrentStateFilterDTO) {
    let cType = Array<string>();
    if (currentStateFilterDTO.filterContainers?.length) {
      cType = currentStateFilterDTO.filterContainers;
    } else {
      currentStateFilterDTO.containerTypes.forEach((containerType) => {
        if (containerType.checked) {
          cType.push(containerType.code);
        }
      });
    }

    const body: any = {
      containerTypes: cType,
      filter: {
        torn: filterTornOptions[currentStateFilterDTO.filterTorn]
      }
    };

    return cType.length == 0
      ? null
      : this.httpClientBattery.postUnique(
          environment.apiBaseUrl + "/kpis/battery-levels",
          body
        );
  }

  getAlertSites(currentStateFilterDTO: CurrentStateFilterDTO) {
    let cType = Array<string>();
    if (currentStateFilterDTO.filterContainers?.length) {
      cType = currentStateFilterDTO.filterContainers;
    } else {
      currentStateFilterDTO.containerTypes.forEach((containerType) => {
        if (containerType.checked) {
          cType.push(containerType.code);
        }
      });
    }

    return cType.length == 0
      ? null
      : this.httpClientAlertSites.postUnique(
          environment.apiBaseUrl + "/kpis/alert-sites",
          cType
        );
  }

  getAllCurrentStates() {
    return this.httpClientAllCurrent.get(
      environment.apiBaseUrl + "/device-containers/all-current-states"
    );
  }

  logSlow(log: string) {
    return this.httpClientSlowLog.post(
      environment.apiBaseUrl + "device-containers/slowLog",
      { log }
    );
  }

  getClusterCurrentStates(deviceIds: string[]) {
    return this.httpClientClusterDevice.post(
      environment.apiBaseUrl + "device-containers/cluster-current-states",
      deviceIds
    );
  }

  createListFilter(currentStateFilterDTO: CurrentStateFilterDTO) {
    let listColor: string[] = [];

    let filter: string = "?filter=";
    let colorFilter: Object = {};
    for (const filterColor of currentStateFilterDTO.filtersColor) {
      if (filterColor.show) {
        if (
          filterColor.marker.includes("orange") &&
          !listColor.includes("ORANGE")
        ) {
          listColor.push("ORANGE");
        } else if (!filterColor.marker.includes("orange")) {
          listColor.push(filterColor.marker.toUpperCase());
        }
      }
    }
    if (currentStateFilterDTO.filtersColor) {
      const andFilter = { statusColor: { inq: listColor } };

      for (const filterColor of currentStateFilterDTO.filtersColor) {
        if (filterColor.show && filterColor.marker.includes("orange")) {
          andFilter[filterColor.flag] = true;
        }
      }
      if (currentStateFilterDTO.filterLastAuthorizedSite) {
        andFilter["lastAuthorizedSite"] =
          currentStateFilterDTO.filterLastAuthorizedSite;
      }
      if (currentStateFilterDTO.filterLastSites?.length) {
        andFilter["lastAuthorizedSites"] = {
          inq: currentStateFilterDTO.filterLastSites
        };
      }

      if (
        currentStateFilterDTO.filterStatusDays &&
        currentStateFilterDTO.filterStatusDays.start !== undefined &&
        currentStateFilterDTO.filterStatusDays.end !== undefined
      ) {
        andFilter["statusDays"] = currentStateFilterDTO.filterStatusDays;
      }

      if (currentStateFilterDTO.filterConfidenceRadius?.length) {
        andFilter["confidenceRadiusInMeter"] = {
          inq: currentStateFilterDTO.filterConfidenceRadius
        };
      }

      if (currentStateFilterDTO.filterTorn) {
        andFilter["torn"] = filterTornOptions[currentStateFilterDTO.filterTorn];
      }

      colorFilter = {
        where: {
          and: [andFilter]
        },
        skip: currentStateFilterDTO.skip,
        limit: currentStateFilterDTO.limit
      };
    }
    filter = filter + JSON.stringify(colorFilter);
    return filter;
  }

  getAllCurrentStatesByContainerType(
    currentStateFilterDTO: CurrentStateFilterDTO
  ) {
    let cType = Array<string>();

    if (currentStateFilterDTO.filterContainers?.length) {
      cType = currentStateFilterDTO.filterContainers;
    } else {
      currentStateFilterDTO.containerTypes.forEach((containerType) => {
        if (containerType.checked) {
          cType.push(containerType.code);
        }
      });
    }

    if (!cType.length) return;

    const filter: string = this.createListFilter(currentStateFilterDTO);
    if (
      currentStateFilterDTO.centerLat &&
      currentStateFilterDTO.centerLng &&
      currentStateFilterDTO.radius
    ) {
      return this.httpClientWrapperPacked.postUnique(
        environment.apiBaseUrl + "device-containers/map-devices-data" + filter,
        {
          requestId: currentStateFilterDTO.requestId,
          centerLat: currentStateFilterDTO.centerLat,
          centerLng: currentStateFilterDTO.centerLng,
          radius: currentStateFilterDTO.radius,
          containerTypes: cType,
          devicesId: currentStateFilterDTO.filterDevicesId
        }
      );
    } else {
      return this.httpClientWrapperPacked.postUnique(
        environment.apiBaseUrl + "device-containers/current-states" + filter,
        {
          requestId: currentStateFilterDTO.requestId,
          containerTypes: cType,
          devicesId: currentStateFilterDTO.filterDevicesId
        }
      );
    }
  }

  getCurrentStatesBySite(
    currentStateFilterDTO: CurrentStateFilterDTO,
    isExport?: boolean
  ) {
    let cType = Array<string>();
    let listColor: string[] = [];

    if (currentStateFilterDTO.filterContainers?.length) {
      cType = currentStateFilterDTO.filterContainers;
    } else {
      currentStateFilterDTO.containerTypes.forEach((containerType) => {
        if (containerType.checked) {
          cType.push(containerType.code);
        }
      });
    }

    if (!cType.length) return;

    let filter: string = "?filter=";
    let colorFilter: Object = {};

    for (const filterColor of currentStateFilterDTO.filtersColor) {
      if (filterColor.show) {
        listColor.push;
        if (
          filterColor.marker.includes("orange") &&
          !listColor.includes("ORANGE")
        ) {
          listColor.push("ORANGE");
        } else if (!filterColor.marker.includes("orange")) {
          listColor.push(filterColor.marker.toUpperCase());
        }
      }
    }
    if (currentStateFilterDTO.filtersColor) {
      const andFilter = {
        currentSite: currentStateFilterDTO.siteId,
        statusColor: { inq: listColor }
      };

      for (const filterColor of currentStateFilterDTO.filtersColor) {
        if (filterColor.show && filterColor.marker.includes("orange")) {
          andFilter[filterColor.flag] = true;
        }
      }

      if (currentStateFilterDTO.filterLastAuthorizedSite) {
        andFilter["lastAuthorizedSite"] =
          currentStateFilterDTO.filterLastAuthorizedSite;
      }
      if (currentStateFilterDTO.filterLastSites?.length) {
        andFilter["lastAuthorizedSites"] = {
          inq: currentStateFilterDTO.filterLastSites
        };
      }
      if (
        currentStateFilterDTO.filterStatusDays &&
        currentStateFilterDTO.filterStatusDays.start !== undefined &&
        currentStateFilterDTO.filterStatusDays.end !== undefined
      ) {
        andFilter["statusDays"] = currentStateFilterDTO.filterStatusDays;
      }

      if (currentStateFilterDTO.filterConfidenceRadius?.length) {
        andFilter["confidenceRadiusInMeter"] = {
          inq: currentStateFilterDTO.filterConfidenceRadius
        };
      }

      if (currentStateFilterDTO.filterTorn) {
        andFilter["torn"] = filterTornOptions[currentStateFilterDTO.filterTorn];
      }
      colorFilter = {
        where: {
          and: [andFilter]
        }
      };
      if (
        currentStateFilterDTO.skip !== undefined &&
        currentStateFilterDTO.limit !== undefined
      ) {
        colorFilter["skip"] = currentStateFilterDTO.skip;
        colorFilter["limit"] = currentStateFilterDTO.limit;
      }

      filter = filter + JSON.stringify(colorFilter);
      if (isExport) {
        filter = filter + "&isExport=true";
      }
    }

    return this.httpClientWrapperPacked.postUnique(
      environment.apiBaseUrl + "device-containers/zoom-site" + filter,
      {
        requestId: currentStateFilterDTO.requestId,
        containerTypes: cType,
        devicesId: currentStateFilterDTO.filterDevicesId
      }
    );
  }
}
