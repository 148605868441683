import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Layout, Config, Data } from "plotly.js";

import { ColorService } from "../../../../services/color.service";
import { IKpiBatteryLevelsDTO, IKpiStateColorsDTO } from "psa-tnt-models";

interface Indicator {
  key: string;
  value: number;
}

@Component({
  selector: "app-graph-kpi",
  templateUrl: "./graph-kpi.component.html",
  styleUrls: ["./graph-kpi.component.scss"]
})
export class GraphKpiComponent implements OnChanges {
  @Input() type: string = "default";
  @Input() statusIndicators: IKpiStateColorsDTO;
  @Input() batteryIndicators: IKpiBatteryLevelsDTO;

  public layout: Layout = {
    height: 40.56,
    font: {
      family: "Roboto"
    },
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0
    },
    paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    dragmode: false,
    barmode: "stack",
    hovermode: false,
    showlegend: false,
    xaxis: {
      showticklabels: false,
      fixedrange: true,
      automargin: false,
      zeroline: false,
      showgrid: false
    },
    yaxis: {
      showticklabels: false
    }
  };

  public config: Config = {
    responsive: true,
    displayModeBar: false,
    staticPlot: true
  };

  public data: Data = [];
  public indicators: Indicator[];
  public totalIndicator: number = 0;
  public indicatorKeys: string[] = [];

  public dataEmpty: boolean = true;
  public graphEnabled: boolean = false;

  constructor(public colorService: ColorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusIndicators) {
      this.initStatusData();
    } else if (changes.batteryIndicators) {
      this.initBatteryData();
    }
  }

  private initBatteryData(): void {
    if (!this.batteryIndicators) {
      this.dataEmpty = true;
      return;
    }

    this.totalIndicator = this.batteryIndicators.totalDevices;
    this.indicatorKeys = [
      "excellent",
      "good",
      "almostEmpty",
      "empty",
      "offline"
    ];

    this.indicators = this.indicatorKeys.map((key) => {
      return {
        key,
        value: this.batteryIndicators[key]
      };
    });

    this.dataEmpty = false;
  }

  private initStatusData(): void {
    if (!this.statusIndicators) {
      this.dataEmpty = true;
      return;
    }

    this.graphEnabled = true;
    this.totalIndicator = this.statusIndicators.activeDevices;
    this.indicatorKeys = ["green", "blue", "orange", "red", "greySeven"];

    this.indicators = this.indicatorKeys.map((key) => {
      const value =
        typeof this.statusIndicators[key] === "number"
          ? this.statusIndicators[key]
          : this.statusIndicators[key].total;

      return {
        key,
        value
      };
    });

    const index = this.indicators.findIndex((item) => item.key === "greySeven");
    this.indicators[index].key = "grey";

    this.data = this.indicators.map((indicator) => {
      return {
        x: [indicator.value],
        name: indicator.key,
        orientation: "h",
        marker: {
          color: this.getColor(indicator.key)
        },
        type: "bar",
        text: `${Math.round((indicator.value / this.totalIndicator) * 100)}%`,
        textposition: "inside",
        insidetextanchor: "middle",
        textfont: {
          color: "white"
        },
        outsidetextfont: { color: "transparent" }
      };
    });

    this.dataEmpty = false;
  }

  public getObjKeys(obj: object): string[] {
    return Object.keys(obj).filter((key) => key !== "total");
  }

  public getColor(key: string): string {
    if (this.statusIndicators) {
      return this.colorService.getColorByStatusColor(key.toUpperCase());
    } else if (this.batteryIndicators) {
      return this.colorService.getColorByStatusBattery(key.toUpperCase());
    }
  }
}
