import { Injectable } from "@angular/core";
import { SvgIconRegistryService } from "angular-svg-icon";

@Injectable({
  providedIn: "root"
})
export class IconRegistryService {
  constructor(private iconRegistry: SvgIconRegistryService) {}

  private icons: { path: string; name: string }[] = [
    { path: "download.svg", name: "download" },
    { path: "dashboard-logo.svg", name: "dashboard-logo" },
    { path: "bin.svg", name: "bin" },
    { path: "pen.svg", name: "pen" },
    { path: "plus.svg", name: "plus" },
    { path: "magnifying-glass.svg", name: "magnifying-glass" },
    { path: "filter.svg", name: "filter" },
    { path: "upload.svg", name: "upload" },
    { path: "right-triangle.svg", name: "right-triangle" },
    { path: "left-triangle.svg", name: "left-triangle" },
    { path: "map.svg", name: "map" },
    { path: "go-to-list.svg", name: "go-to-list" },
    { path: "arrow-right.svg", name: "arrow-right" },
    { path: "arrow-left.svg", name: "arrow-left" },
    { path: "calendar.svg", name: "calendar" },
    { path: "chevron-down.svg", name: "chevron-down" },
    { path: "copy.svg", name: "copy" },
    { path: "check.svg", name: "check" },
    { path: "device-state-pin.svg", name: "device-state-pin" },
    { path: "chevron-left.svg", name: "chevron-left" },
    { path: "chevron-end-left.svg", name: "chevron-end-left" },
    { path: "chevron-right.svg", name: "chevron-right" },
    { path: "chevron-end-right.svg", name: "chevron-end-right" },
    { path: "wifi.svg", name: "wifi" },
    { path: "no-wifi.svg", name: "no-wifi" },
    { path: "radio.svg", name: "radio" },
    { path: "battery/battery-1.svg", name: "battery-1" },
    { path: "battery/battery-2.svg", name: "battery-2" },
    { path: "battery/battery-3.svg", name: "battery-3" },
    { path: "battery/battery-4.svg", name: "battery-4" },
    { path: "device.svg", name: "device" },
    { path: "graph.svg", name: "graph" },
    { path: "info.svg", name: "info" },
    { path: "last-site.svg", name: "last-site" },
    { path: "next-site.svg", name: "next-site" },
    { path: "radius.svg", name: "radius" },
    { path: "site.svg", name: "site" },
    { path: "slider.svg", name: "slider" },
    { path: "warn.svg", name: "warn" },
    { path: "container.svg", name: "container" },
    { path: "add-incident.svg", name: "add-incident" },
    { path: "incident.svg", name: "incident" },
    { path: "color-status.svg", name: "color-status" },
    { path: "close.svg", name: "close" },
    { path: "satellite.svg", name: "satellite" },
    { path: "torn.svg", name: "torn" }
  ];

  public loadIcons(): void {
    this.icons.forEach((icon) => {
      const iconPath = `../../assets/img/${icon.path}`;
      this.iconRegistry.loadSvg(iconPath, icon.name).subscribe();
    });
  }
}
