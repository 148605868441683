import {
  Component,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { MatMenuTrigger } from "@angular/material/menu";
import { SiteDTO } from "../../../models/site";
import { Subject } from "rxjs";

@Component({
  selector: "app-search-site-list",
  templateUrl: "./search-site-list.component.html",
  styleUrls: ["./search-site-list.component.scss"]
})
export class SearchbarListComponent implements OnChanges {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild("inputRef") inputRef: ElementRef;

  @Output() manageInput: EventEmitter<any> = new EventEmitter<any>();

  @Input() inputData: string = "";
  @Input() inputActive: boolean = false;
  @Input() sites: SiteDTO[] = [];
  @Input() type: string;

  public matchDataSource = new MatTableDataSource();
  public searchValueUpdate = new Subject<string>();
  public dataSource = new MatTableDataSource();
  public displayedColumns: string[] = ["key"];
  public sitesMatch: any[] = [];
  public loading: boolean = false;
  public data: any[] = [];
  public iconMap = {
    site: "site",
    departureSite: "last-site",
    arrivalSite: "next-site"
  };

  private readonly STATE_BATCH: number = 12;
  private debounceTime: number = 400;
  private index: number = 20;
  private savedCode: string;

  constructor() {
    this.searchValueUpdate
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(() => {
        this.setData();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputData || changes.sites) {
      this.setData();
    }
    if (changes.inputData) {
      this.savedCode = changes.inputData.currentValue;
    }
  }

  private setData(): void {
    if (this.inputData) {
      this.sitesMatch = [];
      this.data = this.sites.reduce((acc, curr) => {
        const key: string = `${curr.code} - ${curr.label}`;

        if (curr.code.toUpperCase() === this.inputData.toUpperCase()) {
          this.sitesMatch.push({ ...curr, key });
        }
        if (key.toUpperCase().includes(this.inputData.toUpperCase())) {
          return [...acc, { ...curr, key }];
        } else {
          return acc;
        }
      }, []);
    } else {
      this.sitesMatch = [];
      this.data = [];
    }
    this.updateDataSource();
    this.loading = false;
  }

  public updateDataSource() {
    this.matchDataSource.data = this.sitesMatch;
    this.dataSource.data = this.data.slice(0, this.index);
  }

  public inputEnter(): void {
    const siteCode = this.inputData.toUpperCase();

    if (siteCode !== this.savedCode) {
      const siteMatch: SiteDTO = this.sites.find(
        (site) => site.code === siteCode
      );

      if (!siteCode || siteMatch) {
        this.manageInput.emit(siteCode);
        this.trigger.closeMenu();
        this.inputRef.nativeElement.blur();
      }
    }
  }

  public inputFocus(): void {
    this.inputRef.nativeElement.focus();
  }

  public clearInput(event: MouseEvent): void {
    event.stopPropagation();

    this.inputData = "";
    this.manageInput.emit("");
    this.searchValueUpdate.next("");
  }

  public menuClosed(): void {
    if (this.inputActive) {
      this.inputData = this.savedCode;
      this.setData();
    } else {
      this.inputData = "";
    }
  }

  public manageItem(event, item): void {
    event.preventDefault();

    if (item.code === this.savedCode) {
      this.inputData = this.savedCode;
    } else {
      this.manageInput.emit(item.code);
    }
    this.trigger.closeMenu();
  }

  public loadMore = () => {
    let newIndex: number = this.index;
    if (newIndex !== this.data.length) {
      newIndex += this.STATE_BATCH;
      if (newIndex > this.data.length) {
        newIndex = this.data.length;
      }
      this.index = newIndex;
      this.updateDataSource();
    }
  };
}
