import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ColorService {
  constructor() {}

  public colors = {
    blueMain: "#0150E4",
    blueLight: "#E3EEFF",
    blueDark: "#1B4DAC",

    redMain: "#E40101",
    purpleMain: "#3A2164",

    redState: "#D0243F",
    orangeState: "#FC9453",
    greenState: "#66B16C",
    blueState: "#568DC4",
    greyState: "#818181",

    white: "#FCFCFC",
    black: "#161616",

    greyMain: "#757575",
    greyHalf: "#E1E1E1",
    greyLighterHalf: "#F2F2F2",
    greyLight: "#EDEEF3",
    greyDark: "#383838",
    greyShadow: "#797979",
    greyBorder: "#A6A6A6",
    greyBorderHalf: "#BBBBBB",
    greyScrollbar: "#898989",

    siteMarker: "#9494B8",

    transparent: "transparent",
    defaultWhite: "#fff",
    defaultBlack: "#000"
  };

  public getColorByStatusDescription(statusDescription: string): string {
    if (statusDescription === "OK") {
      return this.colors.greenState;
    } else if (statusDescription === "QUIET") {
      return this.colors.greyState;
    } else if (statusDescription === "TRANSIT") {
      return this.colors.blueState;
    } else if (statusDescription === "ALERT" || statusDescription === "TORN") {
      return this.colors.redState;
    }
    return this.colors.orangeState;
  }

  public getColorByStatusColor(statusColor: string): string {
    switch (statusColor) {
      case "GREEN":
        return this.colors.greenState;
      case "ORANGE":
        return this.colors.orangeState;
      case "BLUE":
        return this.colors.blueState;
      case "RED":
        return this.colors.redState;
      case "GREY":
        return this.colors.greyState;
      default:
        return this.colors.black;
    }
  }

  public getColorByStatusBattery(statusBattery: string): string {
    switch (statusBattery) {
      case "EXCELLENT":
        return this.colors.greenState;
      case "GOOD":
        return this.colors.greenState;
      case "ALMOSTEMPTY":
        return this.colors.orangeState;
      case "EMPTY":
        return this.colors.redState;
      case "OFFLINE":
        return this.colors.greyState;
      default:
        return this.colors.black;
    }
  }
}
