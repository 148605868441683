import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IncidentControllerService } from "../../../services/incident-controller.service";
import { userAccess } from "../../../services/user-rights.service";
import { IncidentDTO } from "../../../models/incident";
import { MatDialog } from "@angular/material/dialog";
import { IncidentPopupComponent } from "../../pages/incident/incident-popup/incident-popup.component";

@Component({
  selector: "app-state-displayer-label",
  templateUrl: "./state-displayer-label.component.html",
  styleUrls: ["./state-displayer-label.component.scss"]
})
export class StateDisplayerLabelComponent implements OnInit {
  @Input() labelType: "Incident" | "Torn";
  @Input() deviceId;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();
  private incidents: Array<IncidentDTO> = [];

  constructor(
    private incidentService: IncidentControllerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.incidentService
      .getAllIncidentByDeviceId(this.deviceId)
      .subscribe((res) => {
        this.incidents = res;
      });
  }

  navigateToIncident(incident: IncidentDTO) {
    this.changeTab.emit({
      tab: userAccess.INCIDENT,
      incident: incident
    });
  }

  public getLastIncident() {
    this.incidentService
      .getAllIncidentByDeviceId(this.deviceId)
      .subscribe((res) => {
        return res;
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(IncidentPopupComponent, {
      maxWidth: "80vw",
      minHeight: "70vh",
      width: "80vw",
      height: "70vh",
      data: {
        incidents: this.incidents,
        deviceId: this.deviceId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.navigateToIncident(result);
      }
    });
  }
}
