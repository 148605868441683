import { Component, EventEmitter, Input, Output } from "@angular/core";
import { filterTornOptions } from "../../../../services/device-container-state-controller.service";

@Component({
  selector: "app-header-kpi",
  templateUrl: "./header-kpi.component.html",
  styleUrls: ["./header-kpi.component.scss"]
})
export class HeaderKpiComponent {
  @Output() containers: EventEmitter<any> = new EventEmitter();
  @Output() torn: EventEmitter<string> = new EventEmitter();

  @Input() filterContainers: any[] = [];
  @Input() filterTorn: string;

  public tornFilterOptions: string[] = Object.keys(filterTornOptions);

  constructor() {}

  public manageContainers(containers): void {
    this.containers.emit(containers);
  }

  public manageTorn(torn: string): void {
    this.torn.emit(torn);
  }
}
