import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-header-kpi",
  templateUrl: "./header-kpi.component.html",
  styleUrls: ["./header-kpi.component.scss"]
})
export class HeaderKpiComponent {
  @Output() containers: EventEmitter<any> = new EventEmitter();
  @Output() toggleBattery: EventEmitter<any> = new EventEmitter();

  @Input() filterContainers: any[] = [];
  @Input() batteryVisible: boolean = false;

  constructor() {}

  public manageContainers(containers): void {
    this.containers.emit(containers);
  }

  public tglBattery() {
    this.toggleBattery.emit();
  }
}
