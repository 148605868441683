<div class="cloak" [@enterAnimation] *ngIf="loading">
  <img src="/assets/img/spinner.gif" />
</div>
<div class="subcontent-map" *ngIf="display && !loading">
  <div class="left-info">
    <app-button
      (clicked)="hideMap()"
      iconLeft="arrow-left"
      color="grey"
      [rounded]="true"
    ></app-button>
    <div [matMenuTriggerFor]="menu">
      <app-button-list-single-select
        icon="calendar"
        [selectedElementPath]="lastHoursHistory[lastHoursIndex].text"
      ></app-button-list-single-select>
    </div>
    <mat-menu class="last-hours-menu" #menu="matMenu">
      <mat-radio-group class="last-hours-group" [(ngModel)]="lastHoursIndex">
        <mat-radio-button
          *ngFor="let lh of lastHoursHistory; let i = index"
          class="radio-item"
          [value]="i"
          (change)="lastHoursSelected($event.value)"
        >
          {{ lh.text }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-menu>
    <div class="radius">
      <app-toggle-checkbox
        (toggle)="updatePrecisionCircle()"
      ></app-toggle-checkbox>
      <p>{{ "map.precisionCircle" | translate }}</p>
    </div>
    <div *ngIf="currentItem.isIncident">
      <app-state-displayer-label
        *ngIf="currentItem.isIncident"
        (changeTab)="onChangeTab($event)"
        labelType="Incident"
        (click)="$event.stopPropagation()"
        [deviceId]="currentItem.deviceId"
      ></app-state-displayer-label>
    </div>
  </div>
  <div class="right-info">
    <app-button
      (clicked)="backToList()"
      iconLeft="go-to-list"
      labelPath="map-detail.list"
      [rounded]="true"
      [underlined]="true"
    ></app-button>
    <app-button
      (clicked)="
        redirectToGmap(
          currentItem.latitudeComputed,
          currentItem.longitudeComputed
        )
      "
      iconLeft="map"
      iconRight="arrow-right"
      labelPath="map-detail.maps"
      [rounded]="true"
      [underlined]="true"
    >
    </app-button>
    <app-export-excel
      class="info separate clickable"
      [type]="exportType"
      [data]="historicalState"
      [containerType]="containerType"
    ></app-export-excel>
  </div>
</div>
<div *ngIf="display && !loading">
  <app-device-info-window
    [currentState]="currentItem"
    [isAdmin]="isAdmin('IBMAdmin')"
    (previousPos)="previousPos()"
    (nextPos)="nextPos()"
    (firstPos)="firstPos()"
    (lastPos)="lastPos()"
    [isAtHistoryEnd]="isAtEndOfHistory()"
    [isAtHistoryBeginning]="isAtBeginningOfHistory()"
  ></app-device-info-window>
</div>
