import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";
import { UserRightsService } from "../../../services/user-rights.service";
import { BLUE, GREEN, ORANGE, RED } from "../../../services/geo-utils";

import { ContainerTypeDTO } from "../../../models/container-type";
import { KpisDTO } from "../../../models/kpis";

import { ACTIVE_ONE_STATUS } from "../../../stores/data_store/status-store";

import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output
} from "@angular/core";

@Component({
  selector: "app-kpi",
  templateUrl: "./kpi.component.html",
  styleUrls: ["./kpi.component.scss"]
})
export class KpiComponent implements OnInit, OnDestroy {
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  public greenColor: string = GREEN.replace(/o/, "1");
  public blueColor: string = BLUE.replace(/o/, "1");
  public orangeColor: string = ORANGE.replace(/o/, "1");
  public redColor: string = RED.replace(/o/, "1");

  public orangeDetour: number = 0;
  public orangeStatic: number = 0;
  public orangeLate: number = 0;
  public orange: number = 0;
  public green: number = 0;
  public quiet: number = 0;
  public blue: number = 0;
  public red: number = 0;

  public allContainersTypes: ContainerTypeDTO[];
  public filterContainers: string[] = [];
  public isBattery: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private _userRightsService: UserRightsService,
    private store: Store<any>,
    private deviceContainerStateControllerService: DeviceContainerStateControllerService
  ) {}

  onChangeTab(info: any) {
    this.store.dispatch({ type: ACTIVE_ONE_STATUS, payload: info.status });
    this.navigate.emit({ tab: info.tab });
  }

  ngOnInit() {
    this.initFilters();
    this.initStore();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initStore(): void {
    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((containers: ContainerTypeDTO[]) => {
          this.allContainersTypes = containers;
          this.refreshData();
        })
    );
  }

  private initFilters(): void {
    if (JSON.parse(localStorage.getItem("filterContainers")) !== null) {
      this.filterContainers = JSON.parse(
        localStorage.getItem("filterContainers")
      );
    }
  }

  public changeContainers(containers: string[]) {
    containers?.length
      ? localStorage.setItem("filterContainers", JSON.stringify(containers))
      : localStorage.removeItem("filterContainers");
    this.filterContainers = containers;
    this.refreshData();
  }

  private refreshData() {
    if (
      this.allContainersTypes.length !== 0 &&
      this.deviceContainerStateControllerService.getStateColorsIndicator({
        requestId: 0,
        filterContainers: this.filterContainers,
        containerTypes: this.allContainersTypes
      }) !== null
    ) {
      this.subscription.add(
        this.deviceContainerStateControllerService
          .getStateColorsIndicator({
            requestId: 0,
            filterContainers: this.filterContainers,
            containerTypes: this.allContainersTypes
          })
          .subscribe((kpi: KpisDTO) => {
            this.red = kpi.red;
            this.green = kpi.green;
            this.blue = kpi.blue;
            this.orange = kpi.orange.total;
            this.quiet = kpi.grey;
            this.orangeDetour = kpi.orange.detour;
            this.orangeStatic = kpi.orange.static;
            this.orangeLate = kpi.orange.late;
          })
      );
    }
  }

  public toggleBattery() {
    this.isBattery = !this.isBattery;
  }

  public isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }
}
