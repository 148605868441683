<div class="header-container">
  <div class="header-filter">
    <div class="header-filter-left">
      <app-search-site-list
        (manageInput)="manageFilterSite($event)"
        [type]="'site'"
        [sites]="listSites"
        [inputData]="filterSite"
        [inputActive]="activeSite"
      ></app-search-site-list>

      <app-filter-button
        [type]="'checkbox'"
        [label]="'containerType'"
        [icon]="'container'"
        [checkedData]="filterContainers"
        (manageList)="manageContainers($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'checkbox'"
        [label]="'device'"
        [icon]="'device'"
        [checkedData]="filterDevices"
        (manageList)="manageDevice($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'checkbox'"
        [label]="'lastAuthorizedSite'"
        [icon]="'last-site'"
        [checkedData]="filterLastSites"
        (manageList)="manageLAS($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'range'"
        [label]="'days'"
        [icon]="'calendar'"
        [rangeData]="filterStatusDays"
        (manageList)="manageStatusDays($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'checkbox'"
        [label]="'radius'"
        [icon]="'radius'"
        [checkedData]="filterConfidenceRadius"
        (manageList)="manageConfidenceRadius($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'checkbox'"
        [label]="'incident'"
        [icon]="'incident'"
        [checkedData]="filterIncidents"
        (manageList)="manageIncidents($event)"
      ></app-filter-button>

      <app-filter-button
        [type]="'radio'"
        [label]="'torn'"
        [icon]="'torn'"
        [radioData]="tornFilterOptions"
        [defaultRadioValue]="filterTorn"
        (manageList)="manageTorn($event)"
        [isEmpty]="!tornFilterOptions.length"
        [checkedData]="filterTorn"
        [pathTranslate]="'filter.torn.label.{{item}}'"
      ></app-filter-button>

      <div class="loadingSites">
        <div *ngIf="loading">
          <img
            id="loadingSites"
            src="../../../../../assets/img/spinner-sites.gif"
          />
        </div>
      </div>
    </div>

    <div class="header-filter-right">
      <app-filter-button
        [iconOnly]="true"
        [type]="'checkbox'"
        [label]="'addIncident'"
        [icon]="'add-incident'"
        [isLoading]="isLoading"
        [isEmpty]="isEmptyIncidentDevice"
        (manageList)="addIncident($event)"
      ></app-filter-button>

      <app-status-dots [status]="statusList"></app-status-dots>

      <app-filter-button
        [type]="'colorStatus'"
        [label]="'colorStatus'"
        [icon]="'color-status'"
        [colorStatusData]="statusList"
        (manageList)="manageFilters($event)"
      ></app-filter-button>
    </div>
  </div>
</div>
