import {
  Input,
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges
} from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";

import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { FilterStatus } from "../../../../models/filters";
import {
  IncidentAssociatedDeviceDTO,
  IncidentDTO
} from "../../../../models/incident";
import { SiteDTO } from "../../../../models/site";
import { IncidentControllerService } from "../../../../services/incident-controller.service";
import { filterTornOptions } from "../../../../services/device-container-state-controller.service";

interface DeviceDetail {
  containerType?: string;
  deviceId?: string;
  isIncident?: boolean;
}

@Component({
  selector: "app-header-list",
  templateUrl: "./header-list.component.html",
  styleUrls: ["./header-list.component.scss"]
})
export class HeaderListComponent implements OnInit, OnChanges, OnDestroy {
  @Output() torn: EventEmitter<string> = new EventEmitter();
  @Output() showMap: EventEmitter<void> = new EventEmitter();
  @Output() showList: EventEmitter<void> = new EventEmitter();
  @Output() devices: EventEmitter<string[]> = new EventEmitter();
  @Output() incidents: EventEmitter<number[]> = new EventEmitter();
  @Output() lastSites: EventEmitter<string[]> = new EventEmitter();
  @Output() filter: EventEmitter<FilterStatus> = new EventEmitter();
  @Output() containers: EventEmitter<string[]> = new EventEmitter();
  @Output() statusDays: EventEmitter<string[]> = new EventEmitter();
  @Output() site: EventEmitter<string | undefined> = new EventEmitter();
  @Output() confidenceRadius: EventEmitter<string[]> = new EventEmitter();
  @Output() addIncidentDevices: EventEmitter<number[]> = new EventEmitter();

  @Input() listIncidentDevices: IncidentAssociatedDeviceDTO[];
  @Input() listIncidents: IncidentDTO[];
  @Input() listAddIncident: IncidentDTO[];
  @Input() inputData: DeviceDetail;
  @Input() listSites: SiteDTO[];
  @Input() loading: boolean;
  @Input() isEmptyIncidentDevice: boolean;

  @Input() filterTorn: string;
  @Input() filterStatusDays: string[];
  @Input() filterDevices: string[] = [];
  @Input() filterLastSites: string[] = [];
  @Input() filterIncidents: Number[] = [];
  @Input() filterContainers: string[] = [];
  @Input() filterConfidenceRadius: string[];
  @Input() filterSite: string | undefined = undefined;

  public index: number = 20;
  public isLoading: boolean = false;
  public searchFocus: boolean = false;
  public activeSite: SiteDTO = undefined;
  public activeRadius: number = undefined;
  public statusList: FilterStatus[] = [];

  public tornFilterOptions: string[] = Object.keys(filterTornOptions);

  private subscription: Subscription = new Subscription();

  @ViewChild("selectionCheckbox", { static: true })
  public selectionCheckbox: MatCheckbox;

  constructor(
    private store: Store<any>,
    private incidentService: IncidentControllerService
  ) {}

  public ngOnInit() {
    this.initStore();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.listSites) {
      if (!this.activeSite && this.filterSite) {
        this.activeSite = this.listSites.find(
          (site) => site.code === this.filterSite
        );
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initStore(): void {
    this.subscription.add(
      this.store
        .select("statusStore")
        .subscribe((statusData: Array<FilterStatus>) => {
          this.statusList = statusData;
          localStorage.setItem("statusFilter", JSON.stringify(statusData));
        })
    );
  }

  public siteChanged(): void {
    this.activeSite = this.listSites.find(
      (site) => site.code === this.filterSite
    );
    if (this.activeSite) {
      this.site.emit(this.activeSite.code);
      localStorage.setItem("filterSite", JSON.stringify(this.activeSite.code));
    } else {
      localStorage.removeItem("filterSite");
    }
  }

  public resetSite(): void {
    this.activeSite = undefined;
    this.site.emit(undefined);
    localStorage.removeItem("filterSite");
  }

  public manageFilterSite(siteCode: string): void {
    this.filterSite = siteCode;

    if (!siteCode) {
      this.resetSite();
    } else {
      this.siteChanged();
    }
  }

  public manageContainers(containers: string[]): void {
    this.containers.emit(containers);
  }

  public manageDevice(devices: string[]): void {
    this.devices.emit(devices);
  }

  public manageStatusDays(statusDays: string[]): void {
    this.statusDays.emit(statusDays);
  }

  public manageConfidenceRadius(radius: string[]): void {
    this.confidenceRadius.emit(radius);
  }

  public manageLAS(sites: string[]): void {
    this.lastSites.emit(sites);
  }

  public manageFilters(filter): void {
    this.filter.emit(filter);
  }

  public manageIncidents(incidents: number[]): void {
    this.incidents.emit(incidents);
  }

  public manageTorn(torn: string): void {
    this.torn.emit(torn);
  }

  public addIncident(incidentsIds: number[]): void {
    if (!incidentsIds?.length) {
      this.addIncidentDevices.emit();
    } else {
      this.isLoading = true;

      this.subscription.add(
        this.incidentService
          .getAllIncidentsByIncidentID(incidentsIds)
          .subscribe((incidents: IncidentDTO[]) => {
            let devices: Partial<IncidentAssociatedDeviceDTO>[] = [];
            incidents.forEach((incident) => {
              this.listIncidentDevices.forEach((device) => {
                devices.push({
                  isOpened: false,
                  incidentId: incident.id,
                  deviceId: device.deviceId,
                  lastAuthorizedSite: incident.lastAuthorizedSite,
                  containerType: incident.containerType,
                  assignedBy: incident.createdBy,
                  closingDate: null
                });
              });
            });
            this.incidentService
              .postSelectDevices(devices)
              .subscribe((_res) => {
                incidents.forEach((incident) => {
                  if (!incident.currentSite) incident.currentSite = undefined;
                  this.incidentService
                    .getCountDevices(incident.id)
                    .subscribe((res) => {
                      incident.totalQuantity = res.count;
                      this.subscription.add(
                        this.incidentService.patchIncident(incident).subscribe()
                      );
                    });
                });
                this.isLoading = false;
              });
          })
      );
    }
  }

  public backTolist(): void {
    this.showList.emit();
  }

  public backToMap(): void {
    this.showMap.emit();
  }
}
