import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-information-tooltip",
  templateUrl: "./information-tooltip.component.html",
  styleUrls: ["./information-tooltip.component.scss"]
})
export class InformationTooltipComponent implements OnInit {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() textToDisplay: string;
  @Input() tooltipText: string;
  public translatedTitle?: string;
  public translatedTextToDisplay?: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.title) {
      this.translate
        .get(`tooltip.help.${this.title}.title`)
        .subscribe((res: string) => {
          this.translatedTitle = res;
        });
    }

    if (this.textToDisplay) {
      this.translate
        .stream(`tooltip.help.${this.textToDisplay}.text`)
        .subscribe((res: string) => {
          this.translatedTextToDisplay = res;
        });
    }
  }
}
