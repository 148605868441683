import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import {
  AnalyticsControllerService,
  AnalyticsForGraphics
} from "../../../services/analytics-controller.service";
import { DatePipe } from "@angular/common";
import { NotifierService } from "angular-notifier";
import { TranslateService } from "@ngx-translate/core";
import { IDatesDTO } from "psa-tnt-models";

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"]
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  private readonly notifier: NotifierService;
  private subscription: Subscription = new Subscription();
  private interval: NodeJS.Timeout;

  public datas: AnalyticsForGraphics[];
  public loading: boolean = false;
  public hasData: boolean = false;
  public hasError: boolean = false;

  public errorMsg: string = "";
  public graphNames: string[] = [];
  public selectedGraphConfig: any;
  public configs: any[];

  public filterGraphic: string;
  public filterContainers: string[] = [];
  public filterSites: string[] = [];
  public filterDepartureSites: string[] = [];
  public filterArrivalSites: string[] = [];
  public filterDays: number;

  public todayDate: Date = new Date();
  public filterDateEnd: Date;
  public filterDateStart: Date;

  constructor(
    private analyticsService: AnalyticsControllerService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  public ngOnInit() {
    this.initDatas();
  }

  public ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.subscription.unsubscribe();
  }

  private initDatas(): void {
    this.subscription.add(
      this.analyticsService.getAnalyticsConfigs().subscribe((result) => {
        this.configs = result;
        this.initFilters();
      })
    );
  }

  private initDatesFilter(): void {
    // Set Today to hours, minutes & seconds 0
    this.todayDate.setHours(0, 0, 0, 0);

    // Set End Date to current date
    this.filterDateEnd = new Date(this.todayDate);
    this.filterDateEnd.setDate(this.todayDate.getDate() - 1);

    // Set Start Date at 2 month ago
    this.filterDateStart = new Date(this.todayDate);
    this.filterDateStart.setMonth(this.todayDate.getMonth() - 2);
  }

  private initFilters(): void {
    this.graphNames = this.configs.map((e) => e.name);
    this.selectedGraphConfig = this.configs.find(
      (e) => e.name === this.filterGraphic
    );
    this.initDatesFilter();
  }

  public formatDateGMT(): IDatesDTO {
    const offsetMinutes: number = this.todayDate.getTimezoneOffset();
    const offsetSeconds: number = offsetMinutes * 60;

    return {
      start: Math.round(this.filterDateStart.getTime() / 1000) - offsetSeconds,
      end: Math.round(this.filterDateEnd.getTime() / 1000) - offsetSeconds
    };
  }

  private async getDatas(requestName: string): Promise<AnalyticsForGraphics> {
    const formattedDates: IDatesDTO = this.formatDateGMT();

    return new Promise((resolve, reject) => {
      this.subscription.add(
        this.analyticsService
          .generateAnalyticsData(requestName, this.selectedGraphConfig, {
            containers: this.filterContainers,
            sites: this.filterSites,
            dates: formattedDates,
            departureSites: this.filterDepartureSites,
            arrivalSites: this.filterArrivalSites,
            days: this.filterDays
          })
          .subscribe(
            (result) => {
              resolve(result);
            },
            (error) => {
              this.translate
                .get("analytics.error", {
                  dateStart: this.datePipe.transform(
                    this.filterDateStart,
                    "MM/dd/yyyy"
                  ),
                  dateEnd: this.datePipe.transform(
                    this.filterDateEnd,
                    "MM/dd/yyyy"
                  )
                })
                .subscribe((message) => {
                  this.errorMsg = message;
                });

              reject(error);
            }
          )
      );
    });
  }

  private async updateDatas(): Promise<void> {
    this.hasData = false;
    this.hasError = false;

    if (this.filterGraphic && this.filterContainers?.length) {
      this.loading = true;

      try {
        if (this.selectedGraphConfig?.requests?.length > 0) {
          this.datas = [];

          const reqs = this.selectedGraphConfig.requests;
          for (let i = 0; i < reqs.length; i++) {
            this.datas.push(await this.getDatas(reqs[i]));
          }

          this.loading = false;
          this.hasData = true;
          this.hasError = false;
        } else {
          this.loading = false;
          this.hasData = false;
          this.hasError = true;
          this.notifier.notify("error", "No requests data found.");
        }
      } catch (err) {
        this.datas = [];
        this.loading = false;
        this.hasData = false;
        this.hasError = true;
        this.notifier.notify("error", this.errorMsg);
      }
    }
  }

  private checkCallUpdate(): void {
    const maxContainers: number | undefined =
      this.selectedGraphConfig?.frontConfig?.maxContainers;

    if (!maxContainers || this.filterContainers?.length <= maxContainers) {
      this.updateDatas();
    } else {
      this.hasData = false;
    }
  }

  public manageGraphic(graphic): void {
    this.filterGraphic = graphic;
    this.selectedGraphConfig = this.configs.find(
      (e) => e.name === this.filterGraphic
    );
    this.checkCallUpdate();
  }

  public manageContainers(containers): void {
    this.filterContainers = containers;
    this.checkCallUpdate();
  }

  public manageSites(sites: string[]): void {
    this.filterSites = sites;
    this.checkCallUpdate();
  }

  public manageDepartureSites(sites: string[]): void {
    this.filterDepartureSites = sites;
    this.checkCallUpdate();
  }

  public manageArrivalSites(sites: string[]): void {
    this.filterArrivalSites = sites;
    this.checkCallUpdate();
  }

  public manageDays(days: number): void {
    this.filterDays = days;
    this.checkCallUpdate();
  }

  public manageDates(dates: Date[]): void {
    if (dates) {
      this.filterDateStart = dates[0];
      this.filterDateEnd = dates[1];
    } else {
      this.initDatesFilter();
    }
    this.checkCallUpdate();
  }
}
