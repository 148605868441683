import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpClientWrapper } from "./http-client.wrapper";
import { TranslateService } from "@ngx-translate/core";

export interface FiltersData {
  key: string | number;
  keyDisplayed: string;
  checked?: boolean;
}

export interface FilterInputData {
  img: string;
  label: string;
}

export interface FiltersOptions {
  limit: number;
  skip: number;
  search?: string;
}

/**
 * Filters controller service call api rest for the front filters data - return FiltersData
 */

@Injectable()
export class FiltersControllerService {
  constructor(
    private translate: TranslateService,
    public httpClientWrapper: HttpClientWrapper<FiltersData>
  ) {}

  public getFiltersContainers(
    filters: FiltersOptions
  ): Observable<FiltersData[]> {
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}filters/containerTypes?filter=${JSON.stringify(filters)}`
    );
  }

  public getFiltersDevices(filters: FiltersOptions): Observable<FiltersData[]> {
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}filters/devices?filter=${JSON.stringify(filters)}`
    );
  }

  public getFiltersSites(filters: FiltersOptions): Observable<FiltersData[]> {
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}filters/sites?filter=${JSON.stringify(filters)}`
    );
  }

  private formatStatusDaysKey(range: string): string {
    let key: string = "";

    if (!range.includes("-")) {
      this.translate
        .get(`filter.statusDays.${range}`, { maxDays: 360 })
        .subscribe((t) => (key = t));
    } else {
      this.translate
        .get("filter.statusDays.range", {
          start: range.split("-")[0],
          end: range.split("-")[1]
        })
        .subscribe((t) => (key = t));
    }
    return key;
  }

  public getFilterStatusDays(filters: FiltersOptions): FiltersData[] {
    const statusDaysRange: string[] = [
      "0",
      "1",
      "2",
      "3-7",
      "8-14",
      "15-30",
      "31-90",
      "91-360",
      "others"
    ];

    return statusDaysRange.reduce((acc, range) => {
      const key: string = range;
      const search: string = filters.search;
      const keyDisplayed: string = this.formatStatusDaysKey(range);

      if (
        !search ||
        (search && (key.includes(search) || keyDisplayed.includes(search)))
      ) {
        return [...acc, { key, keyDisplayed }];
      } else {
        return acc;
      }
    }, []);
  }

  private formatConfidenceRadiusKey(range: string): string {
    let key: string = "";

    if (!range.includes("-")) {
      this.translate
        .get(`filter.confidenceRadius.${range}`, { maxRadius: 5000 })
        .subscribe((t) => (key = t));
    } else {
      this.translate
        .get("filter.confidenceRadius.range", {
          start: range.split("-")[0],
          end: range.split("-")[1]
        })
        .subscribe((t) => (key = t));
    }

    return key;
  }

  public getFilterRadius(filters: FiltersOptions): FiltersData[] {
    const confidenceRadiusRange: string[] = [
      "0-100",
      "101-1000",
      "1001-5000",
      "others"
    ];

    return confidenceRadiusRange.reduce((acc, range) => {
      const key: string = range;
      const search: string = filters.search;
      const keyDisplayed: string = this.formatConfidenceRadiusKey(range);

      if (
        !search ||
        (search && (key.includes(search) || keyDisplayed.includes(search)))
      ) {
        return [...acc, { key, keyDisplayed }];
      } else {
        return acc;
      }
    }, []);
  }

  public getFilterIncidentsOpened(
    filters: FiltersOptions
  ): Observable<FiltersData[]> {
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}filters/incidents?filter=${JSON.stringify(filters)}&isOpened=true`
    );
  }

  public getFilterIncidents(
    filters: FiltersOptions
  ): Observable<FiltersData[]> {
    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}filters/incidents?filter=${JSON.stringify(filters)}`
    );
  }
}
