<notifier-container></notifier-container>
<app-container-type-sidenav></app-container-type-sidenav>

<div class="kpi-container">
  <app-header-kpi
    (containers)="changeContainers($event)"
    (torn)="changeTorn($event)"
    [filterContainers]="filterContainers"
    [filterTorn]="filterTorn"
  >
  </app-header-kpi>

  <!-- Status Colors Indicator -->
  <div class="kpi-content">
    <app-graph-kpi
      *ngIf="!loading.stateColors"
      [type]="'statusColors'"
      [statusIndicators]="statusIndicators"
    ></app-graph-kpi>
    <div *ngIf="loading.stateColors" class="kpi-skeleton">
      <div class="skeleton"></div>
    </div>

    <!-- Battery Indicator -->
    <div *ngIf="loading.battery" class="kpi-skeleton">
      <div class="skeleton"></div>
    </div>
    <app-graph-kpi
      *ngIf="!loading.battery"
      [type]="'battery'"
      [batteryIndicators]="batteryIndicators"
    ></app-graph-kpi>

    <!-- Alerts Sites Indicator - TMP DISABLE-->
    <!-- <div *ngIf="loading.alertSites" class="kpi-skeleton alert-sites">
      <div class="skeleton"></div>
    </div>
    <div *ngIf="!loading.alertSites" class="kpi-stats-container">
      <div class="kpi-stats-content kpi-last-sites">
        <div class="kpi-stats-header">
          <h1 class="kpi-stats-title">{{ "kpi.alertsBySites" | translate }}</h1>

          <svg-icon class="kpi-stats-icon" [name]="'alert-site'"></svg-icon>
        </div>
        <div class="kpi-stats-body">
          <div class="kpi-stat-overview" *ngFor="let alert of alertSites">
            <h2 class="kpi-stat-title">{{ alert.code }}</h2>
            <p class="kpi-stat-label">{{ alert.label }}</p>
            <h3 class="kpi-stat-count">{{ alert.count }}</h3>
          </div>
          <p *ngIf="!alertSites?.length" class="kpi-stats-empty">
            {{ "kpi.noData" | translate }}
          </p>
        </div>
      </div>
    </div> -->
  </div>
</div>
