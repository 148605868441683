<div class="site-sidenav" [@slideInOut]="openingState">
  <mat-accordion class="sidenav-sections" multi>
    <div class="site-title-wrapper">
      <p class="site-title" [matTooltip]="siteTitle">{{ siteTitle }}</p>
    </div>
    <div class="information-divider"></div>
    <mat-expansion-panel class="site-info-wrapper sidenav-section" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="sidenav-subtitle">{{
          "sideSite.siteInfo" | translate
        }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="icon-info-wrapper">
        <div class="single-icon-info-wrapper">
          <svg-icon name="container" class="icon"></svg-icon>
          <p class="sidenav-small-text">
            {{
              "sideSite.containers"
                | translate: { totalNumber: totalDeviceNumber }
            }}
          </p>
        </div>
        <div class="single-icon-info-wrapper">
          <svg-icon
            [name]="macAddressesMapped ? 'wifi' : 'no-wifi'"
            class="icon"
          ></svg-icon>
          <p class="sidenav-small-text">
            {{
              (macAddressesMapped
                ? "sideSite.macAddressesMapped"
                : "sideSite.macAddressesNotMapped"
              ) | translate
            }}
          </p>
        </div>
      </div>
    </mat-expansion-panel>
    <div class="information-divider"></div>
    <mat-expansion-panel
      class="sidenav-section"
      [expanded]="checkLength(containerTypeValues)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="sidenav-subtitle">
          {{ "sideSite.containerType" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        id="containerTypePieChart"
        [class.empty-graph]="
          !containerTypeLabels.length || !containerTypeValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!containerTypeLabels.length || !containerTypeValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="sidenav-section"
      [expanded]="checkLength(containerTypeValues)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="sidenav-subtitle">
          {{ "sideSite.status" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        id="statusBarChart"
        [class.empty-graph]="
          !containerTypeLabels.length || !containerTypeValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!containerTypeLabels.length || !containerTypeValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </mat-expansion-panel>
    <div class="information-divider"></div>
    <mat-expansion-panel
      class="sidenav-section"
      [expanded]="checkLength(meanStaticDaysValues)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="sidenav-subtitle">
          {{
            (meanStaticDaysLabels.length >= 5
              ? "sideSite.staticBarChartTitleTop5"
              : "sideSite.staticBarChartTitle"
            ) | translate
          }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        id="staticBarChart"
        [class.empty-graph]="
          !meanStaticDaysLabels.length || !meanStaticDaysValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!meanStaticDaysLabels.length || !meanStaticDaysValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="sidenav-section"
      [expanded]="checkLength(alertValues)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="sidenav-subtitle">
          {{
            (alertLabels.length >= 5
              ? "sideSite.alertBarChartTitleTop5"
              : "sideSite.alertBarChartTitle"
            ) | translate
          }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        id="alertBarChart"
        [class.empty-graph]="!alertLabels.length || !alertValues.length"
      >
        <p
          class="sidenav-small-text"
          *ngIf="!alertLabels.length || !alertValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
