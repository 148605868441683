<app-header-list
  [loading]="loading"
  [inputData]="inputData"
  (showList)="close()"
  (showMap)="backToMap()"
  (filter)="updateFilter($event)"
>
</app-header-list>
<div class="list-detail-content">
  <div class="array-wrapper">
    <app-state-displayer-column-names
      type="ListDetail"
      [hasCheckbox]="false"
    ></app-state-displayer-column-names>
    <div
      class="data"
      [infiniteScroll]="loadMore"
      [scrollRatio]="0.4"
      [initScrollThreshold]="50"
    >
      <div *ngIf="!listHistoSorted">
        <div *ngFor="let _ of [].constructor(20)" class="skeleton-placeholders">
          <app-state-displayer-skeleton-line
            type="ListDetail"
          ></app-state-displayer-skeleton-line>
        </div>
      </div>
      <div *ngFor="let historicalState of listHistoSorted; let i = index">
        <div *ngIf="i" class="information-divider"></div>
        <app-state-displayer
          [(currentState)]="listHistoSorted[i]"
          listDetailDisplay="true"
        ></app-state-displayer>
      </div>
    </div>
  </div>
  <div class="list-info">
    <p *ngIf="listHistoSorted" class="total-devices">
      {{
        "listDetail.totalDevices"
          | translate
            : {
                currentDeviceNumber: listHistoSorted.length,
                totalDeviceNumber: totalFiteredHistoricalStates
              }
      }}
    </p>
    <div class="download-button">
      <app-export-excel
        class="list-tfoot-export"
        [type]="exportType"
        [data]="exportData"
        [disabled]="!listHistoSorted"
      ></app-export-excel>
    </div>
  </div>
</div>
