<div class="kpi-graph fade-in">
  <div class="kpi-graph-title">
    <div class="title-main">
      <h1 class="kpi-graph-title-label">
        {{ "kpi.graph." + type + ".title" | translate }}
      </h1>
      <app-information-tooltip
        [textToDisplay]="type"
        [icon]="'help'"
      ></app-information-tooltip>
    </div>
    <p class="kpi-graph-title-total">
      {{ totalIndicator }}
    </p>
  </div>
  <div *ngIf="!dataEmpty" class="kpi-graph-content">
    <div class="kpi-graph-container" *ngIf="graphEnabled">
      <plotly-plot
        [data]="data"
        [id]="'plot-graph-analytic-1'"
        [layout]="layout"
        [config]="config"
      ></plotly-plot>
    </div>
    <div class="kpi-graph-indicators">
      <div class="kpi-graph-indicator" *ngFor="let indicator of indicators">
        <div class="kpi-graph-indicator-infos">
          <svg-icon
            [ngClass]="{
              'indicator-infos-icon': true,
              battery: !!batteryIndicators
            }"
            [name]="indicator.key"
          ></svg-icon>
          <h2
            class="indicator-infos-count"
            [style.color]="getColor(indicator.key)"
          >
            {{ indicator.value }}
          </h2>
          <p class="indicator-infos-status">
            {{ "kpi.status." + indicator.key | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
