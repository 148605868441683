<h2 mat-dialog-title>
  {{ "incident.popup.title" | translate }} {{ deviceId }}
</h2>
<mat-dialog-content>
  <app-manager-list
    class="list-popup"
    [displayObjects]="incidents"
    [properties]="incidentProperties"
    (selectElement)="onClickIncident($event)"
  ></app-manager-list>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="incident-popup-buttons">
    <app-button
      (clicked)="onClose()"
      class="incident-popup-button"
      [labelPath]="'misc.cancel'"
    ></app-button>
  </div>
</mat-dialog-actions>
