<div class="table">
  <div class="table-toolbar">
    <div class="search-block">
      <!-- Toolbar icon -->
      <div class="toolbar-icon">
        <div class="icon-toolbar-icon">
          <svg-icon
            name="magnifying-glass"
            [svgStyle]="{ fill: '#161616' }"
          ></svg-icon>
        </div>
      </div>
      <!-- Searchbar -->
      <div class="searchbar">
        <div class="search-input" *ngIf="searchClicked">
          <input
            class="text-search-input"
            type="text"
            placeholder="{{ 'misc.search' | translate }}"
            [(ngModel)]="search"
            (keyup.enter)="onSearchClicked()"
            (ngModelChange)="this.searchValueUpdate.next($event)"
          />
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="toolbar-button">
      <!-- Delete button -->
      <div
        class="delete-button-icon-only"
        *ngIf="hasDeleteButton"
        (click)="onDeleteclicked()"
      >
        <div class="delete-icon">
          <svg-icon
            name="bin"
            [svgStyle]="{
              fill: '#161616',
              'height.px': 16,
              'width.px': 16
            }"
          ></svg-icon>
        </div>
      </div>
      <!-- Filter button -->
      <div class="filter-button-icon-only" (click)="onFilterClicked()">
        <div class="filter-icon">
          <svg-icon name="filter" [svgStyle]="{ fill: '#161616' }"></svg-icon>
        </div>
      </div>
      <!-- Action button 1 -->
      <app-button
        iconRight="plus"
        labelPath="manager.list.addElem"
        type="filled"
        (clicked)="onAddElement()"
      ></app-button>
      <!-- Action button 2 -->
      <app-button
        *ngIf="hasModalUpload"
        iconRight="upload"
        labelPath="manager.list.loadFile"
        type="filled"
        (clicked)="openDialog()"
        class="button-primary-2"
      ></app-button>
      <!-- Action button 3 -->
      <app-button
        *ngIf="referenceType"
        iconRight="download"
        labelPath="manager.list.downloadFile"
        type="filled"
        (clicked)="downloadFile()"
        class="button-primary-2"
        [loading]="downloadLoading"
      ></app-button>
      <!-- Action button 4 -->
      <app-button
        *ngIf="referenceType"
        iconRight="download"
        labelPath="manager.list.downloadTemplate"
        type="filled"
        (clicked)="downloadTemplate()"
        class="button-primary-2"
      ></app-button>
    </div>
  </div>
  <!-- Table Header -->
  <div class="data-table-head">
    <div class="cell" *ngFor="let prop of properties">
      <div class="cell-content">
        <div class="checkbox-content" *ngIf="false">X</div>
        <div class="text-content">
          <mat-checkbox
            *ngIf="prop.property === 'checked' && allCheck !== undefined"
            (click)="selectAllDevices($event)"
            [checked]="allCheck"
          ></mat-checkbox>
          {{
            !prop.label
              ? ""
              : ("manager.inputs." + prop.label + ".title" | translate)
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="data-table-rows">
    <div class="filter-dropdown" *ngIf="filterClicked">
      <div class="filter-dropdown" *ngFor="let prop of properties">
        <div
          [ngClass]="{
            'filter-dropdown-input': true,
            notEmpty: prop.canBeFiltered
          }"
        >
          <input
            class="filter-dropdown-text-input"
            *ngIf="prop.canBeFiltered"
            type="text"
            placeholder="{{ 'misc.placeholder.filterColumn' | translate }}"
            [(ngModel)]="prop.filter"
            (keyup.enter)="onFilterChecked()"
            (ngModelChange)="this.filterValueUpdate.next($event)"
          />
        </div>
      </div>
    </div>
    <div *ngIf="loading">
      <div class="object-holder">
        <div
          class="line shine"
          [ngStyle]="{ width: prop.width + 'vw' }"
          *ngFor="let prop of properties"
        ></div>
      </div>
      <!-- <div class="object-holder"></div>
      <div class="object-holder"></div>
      <div class="object-holder"></div>
      <div class="object-holder"></div> -->
    </div>
    <div *ngIf="!loading && displayObjects" class="element-holder">
      <div *ngFor="let object of displayObjects; let i = index">
        <app-list-element
          (selectElement)="onSelect($event)"
          [displayObject]="object"
          [properties]="properties"
        ></app-list-element>
      </div>
    </div>
    <div *ngIf="!loading && displayObjects.length === 0" class="not-found">
      <p>{{ "list.notFound" | translate }}</p>
    </div>
  </div>

  <!-- Paginator -->
  <div class="paginator-holder" *ngIf="hasPaginatorPanel">
    <div class="items">
      <div class="items-info">
        <div>
          {{ "manager.list.itemsPerPage" | translate }}
        </div>
        <div class="arrowContainer" [matMenuTriggerFor]="ItemMenu">
          <div class="arrow-button">
            {{ paginatorObject.itemsPerPage }}
            <img class="arrow" [src]="arrow1" />
          </div>
          <mat-menu #ItemMenu="matMenu">
            <div
              *ngFor="
                let number of paginatorObject.itemsPerPageSelector;
                let i = index
              "
            >
              <button mat-menu-item (click)="changeItemsPerPage(number)">
                {{ number }}
              </button>
            </div>
          </mat-menu>
        </div>
      </div>
      <div>
        {{
          "manager.list.idsItems"
            | translate
              : {
                  currMin: paginatorObject.currentItems[0],
                  currMax: paginatorObject.currentItems[1],
                  max: paginatorObject.maxItems
                }
        }}
      </div>
    </div>
    <div class="pages">
      <div class="pages-info">
        <div class="arrowContainer" [matMenuTriggerFor]="pageMenu">
          <div class="arrow-button">
            {{ paginatorObject.currentPage }}
            <img class="arrow" [src]="arrow2" />
          </div>
          <mat-menu #pageMenu="matMenu">
            <div
              *ngFor="let number of paginatorObject.pageSelector; let i = index"
            >
              <button mat-menu-item (click)="changePageSelector(number)">
                {{ number }}
              </button>
            </div>
          </mat-menu>
        </div>
        <div>
          {{
            "manager.list.idsPages"
              | translate
                : {
                    curr: paginatorObject.currentPage,
                    max: paginatorObject.maxPage
                  }
          }}
        </div>
      </div>
      <button
        class="change-page"
        (click)="previousPage()"
        [disabled]="previousPageAvailable"
      >
        <svg-icon
          name="left-triangle"
          [svgStyle]="{
            fill: '#161616',
            'height.px': 16,
            'width.px': 16
          }"
        ></svg-icon>
      </button>
      <button
        class="change-page"
        (click)="nextPage()"
        [disabled]="nextPageAvailable"
      >
        <svg-icon
          name="right-triangle"
          [svgStyle]="{
            fill: '#161616',
            'height.px': 16,
            'width.px': 16
          }"
        ></svg-icon>
      </button>
    </div>
  </div>
</div>
