<div class="header-filter-analytics">
  <app-filter-button
    [type]="'radio'"
    [label]="'graphic'"
    [icon]="'graph'"
    [radioData]="graphNames"
    (manageList)="manageGraphics($event)"
    [isEmpty]="!graphNames.length"
    [pathTranslate]="'analytics.navigator.{{item}}.sliderLabel'"
  ></app-filter-button>

  <app-filter-button
    *ngIf="graphConfig?.frontConfig?.containers"
    [type]="'checkbox'"
    [label]="'containerType'"
    [icon]="'container'"
    [maxItems]="graphConfig?.frontConfig?.maxContainers || undefined"
    [checkedData]="filterContainers"
    (manageList)="manageContainers($event)"
  ></app-filter-button>

  <app-filter-button
    *ngIf="graphConfig?.frontConfig?.dateRange"
    [type]="'dateRange'"
    [label]="'dateRange'"
    [icon]="'slider'"
    [dateRangeData]="filterDates"
    [isEmpty]="!filterDates.length"
    (manageList)="manageDates($event)"
  ></app-filter-button>

  <app-filter-button
    *ngIf="graphConfig?.frontConfig?.sites"
    [type]="'checkbox'"
    [label]="'sites'"
    [icon]="'site'"
    [checkedData]="filterSites"
    (manageList)="manageSites($event)"
  ></app-filter-button>

  <app-filter-button
    *ngIf="graphConfig?.frontConfig?.departureSites"
    [type]="'checkbox'"
    [label]="'departureSites'"
    [icon]="'last-site'"
    [checkedData]="filterDepartureSites"
    (manageList)="manageDepartureSites($event)"
  ></app-filter-button>

  <app-filter-button
    *ngIf="graphConfig?.frontConfig?.arrivalSites"
    [type]="'checkbox'"
    [label]="'arrivalSites'"
    [icon]="'next-site'"
    [checkedData]="filterArrivalSites"
    (manageList)="manageArrivalSites($event)"
  ></app-filter-button>

  <app-searchbar-number
    *ngIf="graphConfig?.frontConfig?.days"
    [label]="'days'"
    [icon]="'calendar'"
    [inputData]="filterDays"
    [inputActive]="!!filterDays"
    (number)="manageDays($event)"
  ></app-searchbar-number>
</div>
