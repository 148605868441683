<div
  *ngIf="type === 'inputData'"
  [ngClass]="{
    'button-list': true,
    'button-disabled': isEmpty
  }"
  [matTooltip]="inputData.label"
>
  <div class="icon">
    <svg-icon [name]="icon"></svg-icon>
  </div>
  <div class="txt">
    {{ inputData.label || "" }}
  </div>
</div>

<div
  *ngIf="type !== 'inputData' && iconOnly"
  [ngClass]="{
    'button-list icon-only': true,
    'button-list-active': activeData || icon === 'slider',
    'button-disabled': icon === 'add-incident' && isEmpty
  }"
  [matTooltip]="
    icon === 'add-incident' ? undefined : ('element.title.' + label | translate)
  "
  #buttonList
  mat-button
  [matMenuTriggerFor]="
    icon === 'add-incident' && isEmpty ? undefined : menuHeader
  "
  (menuOpened)="menuOpened()"
>
  <div class="icon only">
    <svg-icon
      *ngIf="!isEmpty || icon === 'add-incident'"
      [class]="
        activeData || icon === 'slider'
          ? 'button-list-icon-only-active'
          : 'button-list-icon-only'
      "
      [name]="icon"
    ></svg-icon>
    <mat-spinner
      *ngIf="isEmpty && icon !== 'add-incident'"
      [diameter]="16"
      class="button-list-icon-only"
    ></mat-spinner>
  </div>
  <div class="icon only">
    <mat-icon>expand_more</mat-icon>
  </div>
</div>

<div
  *ngIf="type !== 'inputData' && !iconOnly"
  [ngClass]="{
    'button-list': true,
    'button-list-active': activeData || icon === 'slider',
    'button-disabled': !isEmpty
  }"
  #buttonList
  mat-button
  [matMenuTriggerFor]="menuHeader"
  (menuOpened)="menuOpened()"
>
  <div class="icon">
    <svg-icon
      *ngIf="!isEmpty"
      [class]="
        activeData || icon === 'slider'
          ? 'button-list-icon-active'
          : 'button-list-icon'
      "
      [name]="icon"
    ></svg-icon>
    <mat-spinner *ngIf="isEmpty" [diameter]="16"></mat-spinner>
  </div>
  <div class="txt">
    {{ "element.title." + label | translate }}
  </div>
  <div class="icon">
    <mat-icon *ngIf="!activeData">expand_more</mat-icon>
    <p
      [ngClass]="{
        'button-icon-number': true,
        'button-icon-disabled': isEmpty
      }"
      *ngIf="activeData"
    >
      {{ activeData < 100 ? activeData : "99+" }}
    </p>
  </div>
</div>

<mat-menu
  class="button-list-matmenu"
  #menuHeader="matMenu"
  (closed)="applyClosed()"
>
  <div
    *ngIf="type === 'checkbox' || type === 'label'"
    (click)="$event.stopPropagation()"
  >
    <div class="bar">
      <mat-icon class="searchicon">search</mat-icon>
      <input
        class="searchbar"
        #search
        type="text"
        title="Search"
        [(ngModel)]="searchValue"
        (ngModelChange)="this.searchValueUpdate.next($event)"
        [placeholder]="'misc.search' | translate"
      />
    </div>
    <!-- Match data found -->
    <div *ngIf="matchDataSource?.data?.length">
      <div class="button-list-match-title">
        <p>{{ "searchbarSite.match" | translate }}</p>
      </div>
      <div (click)="$event.stopPropagation()" class="button-list-match-table">
        <div class="filter-site-list">
          <mat-table #table [dataSource]="matchDataSource">
            <ng-container matColumnDef="checkedItem">
              <mat-header-cell *matHeaderCellDef matSort>
                {{ displayedColumns[0] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox
                  [disabled]="
                    maxItems <= checkedData.length && !element.checked
                  "
                  color="primary"
                  [checked]="element.checked"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[1] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.keyDisplayed }}
              </mat-cell>
            </ng-container>
            <mat-row
              *matRowDef="let row; columns: displayedColumns; element"
              [ngClass]="{
                'row-checked': row.checked,
                'row-disabled': maxItems <= checkedData.length && !row.checked
              }"
              (click)="manageItem(row, $event, true)"
            ></mat-row>
          </mat-table>
        </div>
      </div>
      <div class="button-list-match-title">
        <p>{{ "searchbarSite.results" | translate }}</p>
      </div>
    </div>

    <div *ngIf="!data?.length" class="menu-empty">
      <p *ngIf="dataFetched">{{ "misc.empty" | translate }}</p>
      <mat-spinner *ngIf="dataLoading" [diameter]="16"></mat-spinner>
    </div>

    <div
      #listSidebar
      [ngClass]="{
        'list-sidebar': true,
        'match-active': matchDataSource?.data?.length
      }"
      [infiniteScroll]="loadMore"
      [scrollRatio]="0.66"
      [initScrollThreshold]="200"
      *ngIf="data?.length"
    >
      <mat-table
        *ngIf="!isEmpty"
        #table
        [dataSource]="dataSource"
        class="button-list-table"
      >
        <ng-container matColumnDef="checkedItem">
          <mat-header-cell *matHeaderCellDef matSort>
            {{ displayedColumns[0] }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [disabled]="maxItems <= checkedData.length && !element.checked"
              color="primary"
              [checked]="element.checked"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="key">
          <mat-header-cell *matHeaderCellDef>
            {{ displayedColumns[1] }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.keyDisplayed }}
          </mat-cell>
        </ng-container>

        <mat-row
          *matRowDef="let row; columns: displayedColumns; element"
          [ngClass]="{
            'row-checked': row.checked,
            'row-disabled': maxItems <= checkedData.length && !row.checked
          }"
          (click)="manageItem(row, $event, true)"
        ></mat-row>
      </mat-table>
      <p class="empty-p" *ngIf="data.length === 0 && label === 'addIncident'">
        {{ "misc.empty" | translate }}
      </p>
    </div>
  </div>
  <div *ngIf="type === 'radio' && !isEmpty" (click)="$event.stopPropagation()">
    <mat-radio-group
      class="button-list-radio-group"
      (change)="manageItemRadio($event)"
      [(ngModel)]="radioItem"
    >
      <mat-radio-button
        *ngFor="let item of radioData"
        class="button-list-radio-item"
        [value]="item"
        >{{ interpolatePathTranslate(item) | translate }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div
    class="range-filter"
    *ngIf="type === 'dateRange'"
    (click)="$event.stopPropagation()"
  >
    <div class="range-container">
      <app-date-picker
        [placeholderPath]="'datepicker.start'"
        [selectedDate]="dateRangeData[0]"
        (date)="manageDates('start', $event)"
      ></app-date-picker>

      <app-date-picker
        [placeholderPath]="'datepicker.end'"
        [selectedDate]="dateRangeData[1]"
        (date)="manageDates('end', $event)"
      ></app-date-picker>
    </div>
  </div>

  <div *ngIf="type === 'colorStatus'" (click)="$event.stopPropagation()">
    <div class="itemList">
      <button
        *ngFor="let filter of colorStatusData"
        mat-menu-item
        [ngClass]="{
          'filter-button': true,
          'filter-show': filter[showType] && !allFiltersActive,
          item: true
        }"
        (click)="manageFilter(filter)"
      >
        <img
          [src]="filter.activeIcon"
          [ngClass]="{
            'filter-icon': true,
            'filter-icon-active': filter[showType]
          }"
        />
        <div class="txt">{{ "misc.status." + filter.title | translate }}</div>
      </button>
    </div>
  </div>

  <app-filter-button-footer
    (clearItems)="clearItems()"
    (applyItems)="applyItems($event)"
    (displayMore)="ondisplayMore()"
    [isNewIncident]="label === 'addIncident' && !checkedData?.length"
    [type]="type"
    [label]="label"
    [isLoading]="isLoading"
  ></app-filter-button-footer>
</mat-menu>
