import { DataDecompressorService } from "../../../../services/data-decompressor.service";
import { IncidentControllerService } from "../../../../services/incident-controller.service";
import { ContainerTypeDTO } from "../../../../models/container-type";
import { TranslateService } from "@ngx-translate/core";
import {
  IncidentAssociatedDeviceDTO,
  IncidentDTO
} from "../../../../models/incident";
import { SiteDTO } from "../../../../models/site";
import {
  FilterIncident,
  FilterStatus,
  FilterDevice,
  FilterRange,
  CheckedSite,
  CheckedContainerType
} from "../../../../models/filters";

import { MatCheckbox } from "@angular/material/checkbox";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Input,
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges
} from "@angular/core";

const DEVICES_TYPE = "devices";
const INCIDENT_TYPE = "incidents";
const LAST_SITES_TYPE = "lastSites";
const STATUS_DAYS_TYPE = "statusDays";
const CONFIDENCE_RADIUS_TYPE = "confidenceRadius";

interface DeviceDetail {
  containerType?: string;
  deviceId?: string;
  isIncident?: boolean;
}

@Component({
  selector: "app-header-list",
  templateUrl: "./header-list.component.html",
  styleUrls: ["./header-list.component.scss"]
})
export class HeaderListComponent implements OnInit, OnChanges, OnDestroy {
  @Output() containers: EventEmitter<ContainerTypeDTO[]> = new EventEmitter();
  @Output() addIncidentDevices: EventEmitter<number[]> = new EventEmitter();
  @Output() incident: EventEmitter<FilterIncident> = new EventEmitter();
  @Output() site: EventEmitter<string | undefined> = new EventEmitter();
  @Output() filter: EventEmitter<FilterStatus> = new EventEmitter();
  @Output() lastSites: EventEmitter<string[]> = new EventEmitter();
  @Output() incidents: EventEmitter<number[]> = new EventEmitter();
  @Output() devices: EventEmitter<string[]> = new EventEmitter();
  @Output() showList: EventEmitter<void> = new EventEmitter();
  @Output() statusDays: EventEmitter<string[]> = new EventEmitter();
  @Output() confidenceRadius: EventEmitter<string[]> = new EventEmitter();
  @Output() torn: EventEmitter<boolean> = new EventEmitter();
  @Output() showMap: EventEmitter<void> = new EventEmitter();

  @Input() filterIncident: FilterIncident;
  @Input() listIncidentDevices: IncidentAssociatedDeviceDTO[];
  @Input() listIncidents: IncidentDTO[];
  @Input() listAddIncident: IncidentDTO[];
  @Input() inputData: DeviceDetail;
  @Input() listSites: SiteDTO[];
  @Input() filterTorn: boolean | undefined;
  @Input() loading: boolean;
  @Input() isEmptyIncident: boolean;
  @Input() isEmptyIncidentDevice: boolean;

  @Input() filterContainers: CheckedContainerType[] = [];
  @Input() filterSite: string = undefined;
  @Input() filterLastSites: string[] = [];
  @Input() filterIncidents: Number[] = [];
  @Input() filterDevices: string[] = [];
  @Input() filterStatusDays: string[];
  @Input() filterConfidenceRadius: string[];

  public checkedLastSites: CheckedSite[] = [];
  public checkedIncidents: IncidentDTO[] = [];
  public checkedDevices: FilterDevice[] = [];
  public checkedStatusDays: FilterRange[] = [];
  public checkedConfidenceRadius: FilterRange[] = [];

  public containersList: CheckedContainerType[] = [];
  public lastSitesList: CheckedSite[] = [];
  public devicesList: FilterDevice[] = [];
  public statusList: FilterStatus[] = [];
  public statusDaysList: FilterRange[] = [];
  public statusDaysRange: string[] = [
    "0",
    "1",
    "2",
    "3-7",
    "8-14",
    "15-30",
    "31-90",
    "91-360",
    "others"
  ];
  public confidenceRadiusList: FilterRange[] = [];
  public confidenceRadiusRange: string[] = [
    "0-100",
    "101-1000",
    "1001-5000",
    "others"
  ];

  public option: number;
  public tornFilterOptions: string[] = ["all", "torn", "noTorn"];

  public isLoading: boolean = false;
  public activeSite: SiteDTO = undefined;
  public searchFocus: boolean = false;
  public index: number = 20;
  public activeRadius: number = undefined;

  private subscription: Subscription = new Subscription();
  private readonly STATE_BATCH: number = 12;

  @ViewChild("selectionCheckbox", { static: true })
  public selectionCheckbox: MatCheckbox;

  constructor(
    private dataDecompressorService: DataDecompressorService,
    private incidentService: IncidentControllerService,
    private translate: TranslateService,
    private store: Store<any>
  ) {
    translate.onLangChange.subscribe(() => {
      this.setStatusDaysList();
      this.setConfidenceRadiusList();
    });
  }

  public ngOnInit() {
    this.initStore();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterContainers) {
      this.filterContainers = this.filterContainers.map((container) => {
        return {
          ...container,
          key: container.code,
          keyDisplayed: `${container.code} - ${container.label}`,
          checked: !!this.filterContainers.find(
            ({ code }) => code === container.code
          )
        };
      });
    }
    if (changes.listSites) {
      this.lastSitesList = this.listSites.map((site) => {
        return {
          ...site,
          key: site.code,
          keyDisplayed: `${site.code} - ${site.label}`,
          checked: this.filterLastSites.includes(site.code)
        };
      });
      if (this.lastSitesList.length) this.formatFilters(LAST_SITES_TYPE);

      if (!this.activeSite && this.filterSite) {
        this.activeSite = this.listSites.find(
          (site) => site.code === this.filterSite
        );
      }
    }
    if (changes.listIncidents && this.listIncidents) {
      this.listIncidents = this.listIncidents.map((incident) => {
        return {
          ...incident,
          key: incident.id,
          keyDisplayed: incident.title,
          checked: this.filterIncidents.includes(incident.id)
        };
      });
      if (this.listIncidents.length) this.formatFilters(INCIDENT_TYPE);
    }
    if (changes.listAddIncident && this.listAddIncident) {
      this.listAddIncident = this.listAddIncident.map((incident) => {
        return {
          ...incident,
          key: incident.id,
          keyDisplayed: incident.title,
          checked: false
        };
      });
    }
    if (changes.filterStatusDays) {
      this.setStatusDaysList();
    }
    if (changes.filterConfidenceRadius) {
      this.setConfidenceRadiusList();
    }
    if (changes.filterDevices && this.devicesList.length) {
      this.formatFilters(DEVICES_TYPE);
    }
    if (changes.filterLastSites && this.lastSitesList.length) {
      this.formatFilters(LAST_SITES_TYPE);
    }
    if (changes.filterIncidents && this.listIncidents?.length) {
      this.formatFilters(INCIDENT_TYPE);
    }
    if (changes.filterStatusDays && this.statusDaysList.length) {
      this.formatFilters(STATUS_DAYS_TYPE);
    }
    if (changes.filterConfidenceRadius && this.confidenceRadiusList.length) {
      this.formatFilters(CONFIDENCE_RADIUS_TYPE);
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initStore(): void {
    this.subscription.add(
      this.store
        .select("statusStore")
        .subscribe((statusData: Array<FilterStatus>) => {
          this.statusList = statusData;
          localStorage.setItem("statusFilter", JSON.stringify(statusData));
        })
    );

    this.subscription.add(
      this.store.select("filters").subscribe((filters) => {
        if (filters?.devices) {
          this.dataDecompressorService
            .unpack(filters.devices)
            .then((devices) => {
              this.devicesList = devices.map((device) => {
                return {
                  ...device,
                  key: device.deviceId,
                  keyDisplayed: device.deviceId,
                  checked: this.filterDevices.includes(device.deviceId)
                };
              });
              this.formatFilters(DEVICES_TYPE);
            });
        }
        if (filters?.containers) {
          this.containersList = filters.containers.map((container) => {
            return {
              ...container,
              key: container.code,
              keyDisplayed: `${container.code} - ${container.label}`,
              checked: !!this.filterContainers.find(
                ({ code }) => code === container.code
              )
            };
          });
        }
      })
    );
    this.checkTornOption(this.filterTorn);
  }

  public siteChanged(): void {
    this.activeSite = this.listSites.find(
      (site) => site.code === this.filterSite
    );
    if (this.activeSite) {
      this.site.emit(this.activeSite.code);
      localStorage.setItem("siteFilter", JSON.stringify(this.activeSite.code));
    } else {
      localStorage.removeItem("siteFilter");
    }
  }

  public resetSite(): void {
    this.activeSite = undefined;
    this.site.emit(undefined);
    localStorage.removeItem("siteFilter");
  }

  public manageFilterSite(siteCode: string): void {
    this.filterSite = siteCode;

    if (!siteCode) {
      this.resetSite();
    } else {
      this.siteChanged();
    }
  }

  public updateFilterIncident(event: FilterIncident) {
    this.incident.emit(event);
  }

  public loadMore = () => {
    let newIndex: number = this.index;
    if (newIndex !== this.listSites.length) {
      newIndex += this.STATE_BATCH;
      if (newIndex > this.listSites.length) {
        newIndex = this.listSites.length;
      }
      this.index = newIndex;
    }
  };

  private setStatusDaysList(): void {
    this.statusDaysList = this.statusDaysRange.map((range) => {
      return {
        range: range,
        key: range,
        keyDisplayed: this.formatStatusDaysKey(range),
        checked: this.filterStatusDays.includes(range)
      };
    });
  }

  private setConfidenceRadiusList(): void {
    this.confidenceRadiusList = this.confidenceRadiusRange.map((range) => {
      return {
        range: range,
        key: range,
        keyDisplayed: this.formatConfidenceRadiusKey(range),
        checked: this.filterConfidenceRadius.includes(range)
      };
    });
  }

  private formatStatusDaysKey(range: string): string {
    let key: string = "";

    if (!range.includes("-")) {
      this.translate
        .get(`filter.statusDays.${range}`, { maxDays: 360 })
        .subscribe((t) => (key = t));
    } else {
      this.translate
        .get("filter.statusDays.range", {
          start: range.split("-")[0],
          end: range.split("-")[1]
        })
        .subscribe((t) => (key = t));
    }

    return key;
  }

  private formatConfidenceRadiusKey(range: string): string {
    let key: string = "";

    if (!range.includes("-")) {
      this.translate
        .get(`filter.confidenceRadius.${range}`, { maxRadius: 5000 })
        .subscribe((t) => (key = t));
    } else {
      this.translate
        .get("filter.confidenceRadius.range", {
          start: range.split("-")[0],
          end: range.split("-")[1]
        })
        .subscribe((t) => (key = t));
    }

    return key;
  }

  private formatFilters(type: string): void {
    if (type === DEVICES_TYPE) {
      this.checkedDevices = this.devicesList.filter(({ deviceId }) =>
        this.filterDevices.includes(deviceId)
      );
    } else if (type === LAST_SITES_TYPE) {
      this.checkedLastSites = this.lastSitesList.filter(({ code }) =>
        this.filterLastSites.includes(code)
      );
    } else if (type === INCIDENT_TYPE) {
      this.checkedIncidents = this.listIncidents.filter(({ id }) =>
        this.filterIncidents.includes(id)
      );
    } else if (type === STATUS_DAYS_TYPE) {
      this.checkedStatusDays = this.statusDaysList.filter(({ key }) =>
        this.filterStatusDays.includes(key)
      );
    } else if (type === CONFIDENCE_RADIUS_TYPE) {
      this.checkedConfidenceRadius = this.confidenceRadiusList.filter(
        ({ key }) => this.filterConfidenceRadius.includes(key)
      );
    }
  }

  public manageContainers(containers) {
    this.containers.emit(containers);
  }

  public manageDevice(devices) {
    this.devices.emit(devices.map(({ deviceId }) => deviceId));
  }

  public manageStatusDays(statusDays) {
    this.statusDays.emit(statusDays.map(({ range }) => range));
  }

  public manageConfidenceRadius(radius) {
    this.confidenceRadius.emit(radius.map(({ range }) => range));
  }

  public manageLAS(sites) {
    this.lastSites.emit(sites.map(({ code }) => code));
  }

  public manageFilters(filter) {
    this.filter.emit(filter);
  }

  public manageIncidents(incidents) {
    this.incidents.emit(incidents.map(({ id }) => id));
  }

  public manageTorn(torn): void {
    const tornValues = {
      noTorn: false,
      torn: true
    };

    this.torn.emit(tornValues[torn]);
  }

  public addIncident(incidents: IncidentDTO[]) {
    if (!incidents?.length) {
      this.addIncidentDevices.emit();
    } else {
      this.isLoading = true;

      incidents = incidents.map(({ checked, ...incident }) => {
        return incident;
      });

      let devices: Partial<IncidentAssociatedDeviceDTO>[] = [];
      incidents.forEach((incident) => {
        this.listIncidentDevices.forEach((device) => {
          devices.push({
            isOpened: false,
            incidentId: incident.id,
            deviceId: device.deviceId,
            lastAuthorizedSite: incident.lastAuthorizedSite,
            containerType: incident.containerType,
            assignedBy: incident.createdBy,
            closingDate: null
          });
        });
      });
      this.subscription.add(
        this.incidentService.postSelectDevices(devices).subscribe((_res) => {
          incidents.forEach((incident) => {
            if (!incident.currentSite) incident.currentSite = undefined;
            this.incidentService
              .getCountDevices(incident.id)
              .subscribe((res) => {
                incident.totalQuantity = res.count;
                this.subscription.add(
                  this.incidentService.patchIncident(incident).subscribe()
                );
              });
          });
          this.isLoading = false;
        })
      );
    }
  }

  public backTolist(): void {
    this.showList.emit();
  }

  public backToMap(): void {
    this.showMap.emit();
  }

  checkTornOption(torn?: boolean | undefined) {
    const localTest = localStorage.getItem("tornFilter");
    if (localTest === null && torn === false) {
      this.option = 0;
    } else {
      this.option = torn ? 1 : 2;
    }
  }
}
