<div class="kpi">
  <div class="in-transit">
    <canvas
      baseChart
      [datasets]="datasetActive"
      [labels]="kpiBattery ? labels.labelsBattery : labels.labelsStatus"
      [options]="optionsActive"
      chartType="doughnut"
    >
    </canvas>
    <div class="selected-item">
      <div class="number">{{ currentValueActive }}</div>
      <div class="label">{{ currentLabelActive }}</div>
    </div>
  </div>
</div>
